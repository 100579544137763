import { forwardRef } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import { CardField } from "../../Common/Template/Card/CardField";
import { t } from "i18next";

export const PartnerLeadOfferCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {    
    return (
        <PageContainer pageType={SystemPageType.Card} title={t('partnerCenter:partnerLeadOfferCard:title')!} endpoint="odata/salesheadertemplate" ref={ref}>
            <CardGroup name="general" label={t('partnerCenter:partnerLeadOfferCard:groups:general')!} isOpen>
                <CardField label={t('partnerCenter:partnerLeadOfferList:fields:id')!} name="id" type={SystemFieldType.Number} readonly />
                <CardField label={t('partnerCenter:partnerLeadOfferList:fields:createdAt')!} name="createdAt" type={SystemFieldType.Date} readonly />
                <CardField label={t('partnerCenter:partnerLeadOfferList:fields:description')!} name="description" type={SystemFieldType.String} />
                <CardField label={t('partnerCenter:partnerLeadOfferList:fields:isBuyed')!} name="isBuyed" type={SystemFieldType.Boolean} readonly />
            </CardGroup>
        </PageContainer>
    );
});