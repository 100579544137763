import { useContext } from "react";
import PageContainer from "../../Common/Template/PageContainer";
import { SystemActionCategory, SystemPageType } from "../../../Model/SystemModels";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { useNavigate } from "react-router-dom";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { t } from "i18next";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";

export const PartnerCustomerSubscriptionList : React.FC = () => {
    const navigate = useNavigate();
    const partnerInfoContext = useContext(PartnerInformationContext);
    
    let listTitle = t('partnerCenter:partnerCustomerSubscriptionList:title')!;
    let customerId = 0;
    let companyName = "";
     
    const buildEndpointList = () : string => {
        const customerIdParam = HttpHelper.getParameter("customerId");
        const companyNameParam = HttpHelper.getParameter("name");

        if (!customerIdParam || !companyNameParam)
            navigate("/app/partner/home");

        listTitle += companyNameParam;

        customerId = customerIdParam;
        companyName = companyNameParam;

        return "/odata/subscription?$filter=partnerId eq "+partnerInfoContext.partner.id+" and customerId eq "+customerId;
    }
    
    return (
        <>
            <PageContainer endpoint={buildEndpointList()} pageType={SystemPageType.List} title={listTitle} backAllowed>                            
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="openCustomerSubscriptionDetail" label={t('partnerCenter:partnerCustomerSubscriptionList:actions:openCustomerSubscriptionDetail')!} iconName="KnowledgeArticle" isPromoted runOnRec onClick={(req: any) => {
                                navigate("/app/partner/administrative/customer/subscription/line?subscriptionId="+req.id+"&customerId="+customerId+"&name="+companyName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:id')!} fieldName="id" minWidth={60} maxWidth={80} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:documentNo')!} fieldName="documentNo" minWidth={250} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:postingDate')!} fieldName="postingDate" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:status')!} fieldName="status" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:source')!} fieldName="source" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:recurringInterval')!} fieldName="recurringInterval" minWidth={130} maxWidth={250} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:currentPeriodStart')!} fieldName="currentPeriodStart" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:currentPeriodEnd')!} fieldName="currentPeriodEnd" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name={t('partnerCenter:partnerCustomerSubscriptionList:fields:cancellationDate')!} fieldName="cancellationDate" minWidth={150} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}