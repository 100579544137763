import { CommandBar, getPropsWithDefaults, IButtonStyles, ICommandBarItemProps, Icon,Text, IStyle, Label, mergeStyles, Persona, PersonaInitialsColor, PersonaSize, PrimaryButton, ProgressIndicator, Separator, Stack, IButtonProps, Dialog, TooltipHost, keyframes, Image, ImageFit, FontIcon } from "@fluentui/react";

import React, { CSSProperties, useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTypeReferenceDirectiveResolutionCache } from "typescript";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { CustomerEnvironment, CustomerProgressChecklist } from "../../../Model/CustomerModels";
import { ActionType, SystemDataLoadingStatus, UserApplicationTask } from "../../../Model/SystemModels";
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import './DashboardComponents.css';
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { DemoVideoModal } from "../../Demo/DemoVideoModal/DemoVideoModal";
import { AxiosInstance } from "axios";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { DemoChatVideoModal } from "../../Demo/DemoChatVideoModal/DemoChatVideoModal";
import { resolve } from "path";
import { rejects } from "assert";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import waitEnvImage from '../../../Assets/Images/droidi2.gif';

//#region User Profile Card

export interface IUserProfileCardProps {
}

const userProfileContainerClassName = mergeStyles([{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
}]);

const userProfileImageContainerClassName = mergeStyles([{
    textAlign: 'center'
}]);


const userProfileTitleClassName = mergeStyles([{
    fontSize: 32,
    fontWeight: 400
}]);

const userProfileTextClassName = mergeStyles([{
    fontSize: 12,
    fontWeight: 400
}]);

export const UserProfileCard : React.FC<IUserProfileCardProps> = (props) => {
    const sessionInfoContext = useContext(SessionStateContext);
    const { t } = useTranslation();
    
    return (
        <Stack key="userProfileContainer" className={userProfileContainerClassName} tokens={{
            childrenGap: 20
        }}>
            <Stack.Item className={userProfileImageContainerClassName}>
                <Persona imageInitials={SystemCore.getUserChars(sessionInfoContext.currentUser)} size={PersonaSize.size120} initialsColor="rgb(0, 128, 137)" />
            </Stack.Item>
            <Stack.Item style={{
                textAlign: 'center'
            }}>
                <Label className={userProfileTitleClassName} >{t('customerCenter:UserProfileCard:welcomeTitle',{name:sessionInfoContext.currentUser.displayName})}</Label>
                <Label className={userProfileTextClassName}>{t('customerCenter:UserProfileCard:welcomeDescription')}</Label>
            </Stack.Item>
            <Stack.Item style={{
                width: '90%',
                display: 'none'
            }}>
                <ProgressIndicator label={t('customerCenter:UserProfileCard:progressIndicatorLabel')} description="20 / 100" percentComplete={0.2} styles={{
                    progressBar: {
                        height: 10, 
                        backgroundColor: 'rgb(0, 128, 137)'
                    },
                    progressTrack: {
                        height: 10,
                        backgroundColor: 'white'
                    },
                    itemDescription: {
                        fontSize: 13
                    },
                    itemName: {
                        fontSize: 16
                    }
                }} />
            </Stack.Item>
        </Stack>
    );
}
//#endregion

//#region Banner


export interface IBannerProps {
    leftContent: JSX.Element;
    rightContent: JSX.Element;
}

const bannerContainerClassName = mergeStyles([{
    width: '100%',
    backgroundColor: 'rgb(217, 240, 242) !important',
    height: 'auto',
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex'
}]);

const leftContainerContentClassName = mergeStyles([{
    width: '32%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center'
}]);

const rightContainerContentClassName = mergeStyles([{
    width: '68%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center'
}]);

export const Banner : React.FC<IBannerProps> = (props) =>  {

    return (
        <Stack key="bannerContainer" horizontal wrap className={bannerContainerClassName} tokens={{
            childrenGap: 0
        }}>
            <Stack.Item className={leftContainerContentClassName}>
                {props.leftContent}
            </Stack.Item>
            <Stack.Item className={rightContainerContentClassName}>
                {props.rightContent}
            </Stack.Item>
        </Stack>
    );

}

//#endregion

//#region Task Progress Card
const dashboardContainerClassName = mergeStyles([{
    width: "98%",
    margin: '0 auto',
    marginTop: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '1em',
    paddingBottom: '1em',
    paddingRight: '1em',
    paddingTop: '1em',
    boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
    borderLeftWidth: '0px',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    borderTopWidth: '2px',
    borderleftStyle: 'solid',
    borderbottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderTopStyle: 'solid',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'transparent'
}]);

export const DashboardContainer : React.FC<{}> = (props) => {
    return (
        <Stack className={dashboardContainerClassName}>
            {props.children}
        </Stack>
    );
}

const taskMainContentClassName = mergeStyles([{
    width: '100%',
    display: 'flex',
    paddingLeft: 10
}]);

const taskMainContentPrimaryButton = mergeStyles([{
    width: 'auto',
    marginRight: 10
}]);

const taskProgressContainerClassName = mergeStyles([{
    width: '100%'
}]);

const taskProgressEntryListContainerClassName = mergeStyles([{
    width: '100%'
}]);

const taskProgressEntriesContainerClassName = mergeStyles([{
    width: '100%'
}]);

const taskProgressTextContainerClassName = mergeStyles([{
    fontSize: 13,
    marginTop: 10,
    paddingLeft: 10,
    color: 'white'
}]);

export interface ITaskProgressCardProps {
    targetId?: string;
    tasks: CustomerProgressChecklist[];
    onSuccess:()=>void;
    onNextClick: (task: CustomerProgressChecklist) => void;
}

export const TaskProgressCard : React.FC<ITaskProgressCardProps> = (props) => {
    const calculatedWidth = 100 / props.tasks.length;

    const progressItemActive = mergeStyles([{
        width: calculatedWidth + '%',
        backgroundColor: '#008089',
        height: 3,
        margin: 10
    }]);
    const progressItemNotActive = mergeStyles([{
        width: calculatedWidth + '%',
        backgroundColor: '#ffffff',
        height: 3,
        margin: 10
    }]);

    const buildSteps = () => {
        let steps: JSX.Element[] = [];
        for(let i = 0; i < props.tasks.length; i++)
        {
            steps.push(<Stack.Item className={props.tasks[i].completed ? progressItemActive : progressItemNotActive}>
                &nbsp;
            </Stack.Item>)
        }
        return steps;
    }

    const getActiveStep = () => {
        let filteredList = props.tasks.filter(e => ! e.completed);

        if (filteredList.length > 0)
            return filteredList[0];
        return undefined;
    }

    const getTaskCompleted = () => {
        let filteredList = props.tasks.filter(e =>  e.completed);
        return filteredList.length + 1;
    }
    
    return (
        <Stack style={{
            backgroundColor: '#00B7C3',
            padding: 20,
            borderRadius: 10,
            width: '90%'
        }} tokens={{
            childrenGap: 30
        }}
        id={props.targetId}>
            <Stack.Item>
                <TaskProgressContent onSuccessCheck={props.onSuccess} activeTask={getActiveStep()!} onNextClick={props.onNextClick} />
            </Stack.Item>
            <Stack.Item>
                <Stack key="progressContainer" className={taskProgressContainerClassName}>
                    <Stack.Item className={taskProgressTextContainerClassName}>
                        {getTaskCompleted()} {t('customerCenter:TaskProgressCard:stepSubtext')!} {props.tasks.length}
                    </Stack.Item>
                    <Stack.Item className={taskProgressEntryListContainerClassName}>
                        <Stack key="progressItemsContainer" horizontal className={taskProgressEntriesContainerClassName}>
                            {buildSteps()}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}
//#endregion

//#region Task Progress Content 
export interface ITaskProgressContentProps {
    activeTask: CustomerProgressChecklist;
    onSuccessCheck:()=>void;
    onNextClick: (task: CustomerProgressChecklist) => void;
}

export const TaskProgressContent : React.FC<ITaskProgressContentProps> = (props) => {
    const [saveInProgress,setSaveInProgress] = useState(false);
    const navigate = useNavigate();
    const customerService = new CustomerService();

    const checkCurrentTask = (activeTask: CustomerProgressChecklist)=>{
        setSaveInProgress(true);
        customerService.checkCurrentTask(activeTask).then((resp) => {
            if(resp){
                customerService.updateCurrentTask(activeTask).then((resp)=>{
                    setSaveInProgress(false);
                    props.onSuccessCheck();
                })
                .catch((jqXHR)=>{
                    setSaveInProgress(false);
                    ToastService.showMessage(MessageType.Error,"Non è stato possibile completare il task: "+jqXHR.message)
                })
            }
            else{
                setSaveInProgress(false);
                ToastService.showMessage(MessageType.Error, "Ci risulta che il task non si ancora completato");
            }
        })
        .catch((jqXHR)=>{
            setSaveInProgress(false);
            ToastService.showMessage(MessageType.Error,jqXHR.message)
        })
    }
    
    let nextButton = <></>;
    if (props.activeTask.showNextButton)
        nextButton =  <PrimaryButton disabled={saveInProgress} text={props.activeTask.translatedNextButtonText} className={taskMainContentPrimaryButton} styles={{
                        root: {
                            backgroundColor: '#008089',
                            borderColor: '#008089',
                        },
                        rootHovered: {
                            backgroundColor: 'rgb(1, 93, 99)',
                            borderColor: 'rgb(1, 93, 99)'
                        },
                        rootFocused: {
                            backgroundColor: 'rgb(1, 93, 99)',
                            borderColor: 'rgb(1, 93, 99)'
                        },
                        rootPressed: {
                            backgroundColor: 'rgb(1, 93, 99)',
                            borderColor: 'rgb(1, 93, 99)'
                        }
                    }} onClick={() => {
                        checkCurrentTask(props.activeTask);
                    }} />

    return (
        <Stack key="mainContent" className={taskMainContentClassName} tokens={{
            childrenGap: 20
        }}>
            <Stack.Item style={{
                color: 'white',
                fontSize: 28,
                fontWeight: 600
            }}>
                {props.activeTask.translatedDescription}
            </Stack.Item>
            <Stack.Item style={{
                fontSize: 16,
                fontWeight: 400,
                color: '#f7f7f7'
            }}>
                {props.activeTask.translatedDetDescription}
            </Stack.Item>
            <Stack.Item>
                <PrimaryButton disabled={saveInProgress} text={props.activeTask.translatedPrimaryButtonText} className={taskMainContentPrimaryButton} styles={{
                    root: {
                        backgroundColor: '#008089',
                        borderColor: '#008089'
                    },
                    rootHovered: {
                        backgroundColor: 'rgb(1, 93, 99)',
                        borderColor: 'rgb(1, 93, 99)'
                    },
                    rootPressed: {
                        backgroundColor: 'rgb(1, 93, 99)',
                        borderColor: 'rgb(1, 93, 99)'
                    }
                }} onClick={() => {
                    if (props.activeTask.actionType == ActionType.OpenLink)
                        window.open(props.activeTask.externalLink);
                    else
                    {
                        props.onNextClick(props.activeTask);
                    }
                        //navigate(props.activeTask.pageEndpoint);
                }} />
                {nextButton}
            </Stack.Item>
        </Stack>
    );
}
//#endregion

//#region Dashboard Command Bar
export interface IDashboardCommandBarProps {
    items: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
}


const commandBarStyle = {
    root: {
        marginBottom: 10,
        padding: 0
    }
};

const dashboardItemActionStyle = {
    root: {
        fontSize: 16,
        color: 'rgb(0, 132, 137) !important',
        backgroundColor: 'rgb(255,255,255) !important',
        selectors: {
            "&:hover": {
                textDecoration: 'underline'
            }
        },
    }
};

const dashboardFarItemActionStyle: IButtonStyles = {
    root: {
        fontSize: 16,
        color: 'white !important',
        backgroundColor: '#E65E6D',
        borderRadius: 5,
        padding: 20,
        boxShadow: 'rgb(34 36 38 / 15%) 0px 1px 1px 1px',
        selectors: {
            "&:hover": {
                
            }
        },
    },
    rootHovered: {
        backgroundColor: '#E97768'
    }
};


export const DashboardCommandBar: React.FC<IDashboardCommandBarProps> = (props) => {
    const [farInterval, setFarInterval] = useState<NodeJS.Timeout | undefined>(undefined);
    const [farClassName, setFarClassName] = useState("");

    let currentItems = props.items;
    for (let i = 0; i < currentItems.length; i++)
        currentItems[i].buttonStyles = dashboardItemActionStyle;
    
    let currentFarItems = props.farItems;
    for (let i = 0; i < currentFarItems.length; i++)
    {
        currentFarItems[i].buttonStyles = dashboardFarItemActionStyle;
        currentFarItems[i].className = farClassName;
    }

    const createInterval = () => {
        return setTimeout(() => {
            if (farClassName == "")
                setFarClassName("purchase-blink");
            else
                setFarClassName("");
            setFarInterval(undefined);
        }, 8000);
    }

    if (props.farItems.length > 0)
    {
        if (farInterval == undefined)
            setFarInterval(createInterval());
    }

    return (
        <CommandBar
            items={currentItems}
            overflowItems={[]}
            farItems={currentFarItems}
            styles={commandBarStyle}
            id="actionBar"
      />
    );
}
//#endregion

//#region Dashboard Content Container

export interface IDashboardContentContainerProps {
    name: string;
    title: string;
}

const dashboardContainerContentClassName = mergeStyles([{
    display: 'flex',
    fleWrap: 'wrap',
    maxWidth: 'calc(100%)'
}]);

const titleContainerClassName = mergeStyles([{
    width: '98%',
    borderBottom: '1px solid rgb(0, 128, 137)',
    color: 'rgb(0, 128, 137)',
    fontSize: 20,
    fontWeight: 500,
    paddingBottom: 5,
    userSelect: 'none',
    selectors: {
        "&:hover": {
            borderBottom: '1px solid #015d63',
            color: '#015d63',
            cursor: 'pointer'
        }
    }
}]);

export const DashboardContentContainer: React.FC<IDashboardContentContainerProps> = (props) => {
    const [iconName,setIconName] = useState("ChevronDown");
    
    const handleOnClick = () => {
        let element = document.getElementById("dashboardContainerContent-" + props.name);
        if (element != undefined)
        {
            if (element.style.visibility != 'hidden')
                element.style.visibility = 'hidden';
            else
                element.style.visibility = 'visible';

            updateIconName();
        }
    }
    
    const updateIconName = () => {
        let element = document.getElementById("dashboardContainerContent-" + props.name);
        if (element != undefined)
        {
            if (element.style.visibility == 'hidden')
                setIconName("ChevronUp");
            else
                setIconName("ChevronDown");
        }
    };

    return (
        <Stack key={props.name} className={dashboardContainerContentClassName}>
            <Stack.Item className={titleContainerClassName} onClick={handleOnClick}>
                <Stack horizontal key={"dashboardTitleContainerContent-" + props.name} style={{
                    width: '100%'
                }}>
                    <Stack.Item style={{
                        width: '95%',
                        fontSize: 20,
                        fontWeight: 500,
                        color: 'inherit'
                    }}>
                        {props.title}
                    </Stack.Item>
                    <Stack.Item style={{
                        width: '5%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 15,
                        color: 'inherit'
                    }}>
                        <Icon iconName={iconName}/>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item id={"dashboardContainerContent-" + props.name} style={{
                paddingTop: 5
            }}>
                {props.children}
            </Stack.Item>
        </Stack>
    );
}
//#endregion

//#region Dashboard Content Row
export interface IDashboardContentRowProps {
    id: string;
}

const dashboardContentRowClassName = mergeStyles([{
    marginTop: '15px !important',
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: 'calc(100%)'
}]);

export const DashboardContentRow: React.FC<IDashboardContentRowProps> = (props) => {
    return (
        <Stack horizontal wrap key={"dashboardContentRow" + props.id} className={dashboardContentRowClassName}>
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Dashboard Content Row
export interface IDashboardContentColumnProps {
}

const dashboardContentColumnClassName = mergeStyles([{
    width: 'auto',
    float: 'left',
    marginLeft: "0px !important"
}]);

export const DashboardContentColumn: React.FC<IDashboardContentColumnProps> = (props) => {
    return (
        <Stack.Item className={dashboardContentColumnClassName}>
            {props.children}
        </Stack.Item>
    );
}

//#endregion

//#region Dashboard Message Card
 
const messageCardTitleContainer = mergeStyles([{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const messageTitleClassName = mergeStyles([{
    fontSize: 35,
    textAlign:'left',
    width: '100%',
    color: 'rgb(0, 128, 137)',
    fontWeight: 400,
}])

const messageCardTextContainer = mergeStyles([{
    width:'100%'
}]);


const messageCardContainerClassName = mergeStyles([{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding:15
}]);

const messageContentContainerClassName = mergeStyles([{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const messageCardTextClassName = mergeStyles([{
    fontSize: 16,
    textAlign:'left'
}]);

const messageIconContentContainerClassName = mergeStyles([{
    width: 'auto',
    fontSize: 'calc(50px + 2vw)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 40
}]);

const messageIconClassName = mergeStyles([{
    backgroundColor: 'rgb(0, 128, 137)',
    padding: 'calc(10%)',
    color: 'white',
    borderRadius: '100%'
}]);

export interface IDashboardMessageCardProps {
    title: string;
    messageIcon: string;
    showIcon?: boolean;
    targetId?: string;
} 

export const DashboardMessageCard: React.FC<IDashboardMessageCardProps> = (props) => {
    let iconContent = <></>;
    if (props.showIcon)
        iconContent = <Stack.Item className={messageIconContentContainerClassName}>
                            <Icon iconName={props.messageIcon} className={messageIconClassName} />
                    </Stack.Item>;
   
    return (
       <Stack horizontal key="messageCardContainer" className={messageCardContainerClassName} id={props.targetId}>
           {iconContent}
            <Stack key="messageContentContainer" tokens={{
                childrenGap: 30
            }} className={messageContentContainerClassName}>
                <Text variant="xxLarge" className={messageTitleClassName}>{props.title}</Text>
                <Stack.Item className={messageCardTextContainer}>
                    <Text variant="medium" className={messageCardTextClassName}>{props.children}</Text>
                </Stack.Item>
            </Stack>
       </Stack>
   );
}
//#endregion

//#region Dashboard Carousel

export interface IDashboardCarouselProps  {
    children: JSX.Element[];
    activeSlide: number;
    containerClassName: string;
}

export const DashboardCarousel = ({children, activeSlide, containerClassName} : IDashboardCarouselProps) => {
    
    const [currentIndex, setCurrentIndex] = useState(activeSlide - 1);
    const [carouselInterval, setCarouselInterval] = useState<number>(0);
    let isSingleSlide: boolean = false;

    const slides = children.map((slide, index) => {
        if(slide.type === React.Fragment){
            isSingleSlide = true;
            return <></>;
        }
        
        return <DashboardCarouselSlide id={index + 1} active={currentIndex == index}>
                    {slide}
                </DashboardCarouselSlide>
    });

    const dots = children.map((slide, index) => {
        if(slide.type === React.Fragment){
            isSingleSlide = true;
            return <></>;
        }
        
        return <span className={(index == currentIndex ? "dot active" : "dot")} onClick={() => handleDotClick(index)}></span> 
    });

    const handleArrowClick = (step: number) => {
        let newIndex = currentIndex;
        newIndex += step;

        if (newIndex > (children.length - 1))
            newIndex = 0;
        else if (newIndex < 0)
            newIndex = children.length - 1;

        setCurrentIndex(newIndex);
    }

    const createInterval = () => {
        return window.setTimeout(() => {
            handleArrowClick(1);
            setCarouselInterval(0);
        }, 5000);
    }

    const handleDotClick = (index: number) => {
        let newIndex = index;
        setCurrentIndex(newIndex);
    }

    if (carouselInterval === 0 && !isSingleSlide)
        setCarouselInterval(createInterval());

    

    return (
        <Stack key={"dashboardCarouselContainer"} className={containerClassName} onMouseEnter={() => {
            clearTimeout(carouselInterval);
        }} 
        onMouseLeave={() => {
            setCarouselInterval(0);
        }}>
            {slides}
            {!isSingleSlide && <a className="prev" onClick={() => handleArrowClick(-1)}>❮</a>}
            {!isSingleSlide && <a className="next" onClick={() => handleArrowClick(1)}>❯</a>}
            <div style={{
                textAlign: 'center',
                position: 'relative',
                bottom: 30
            }}>
                {!isSingleSlide && dots}
            </div>
        </Stack>
    );
}

export interface IDashboardCarouselSlideProps {
    id: number;
    active: boolean;
}

export const DashboardCarouselSlide: React.FC<IDashboardCarouselSlideProps> = (props) => {   
    let currentClassName = "dashboard-carousel-slide fade-animation";
    if (props.active)
        currentClassName += ' slideactive';
    
    return (
        <Stack key={"dashboardCarouselSlide-" + props.id} className={currentClassName}>
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Cue Box
export interface ICueBoxProps {
    name: string;
    title: string;
    value?: any;
    isIconBox?: boolean;
    iconName?: string;
    link:string;
    openExternalLink?: boolean;
}

const cueBoxContainerClassName =mergeStyles([{
    width: 122,
    height: 138,
    marginTop: 10,
    color: 'white',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '#008089',
    selectors: {
        "&:hover": {
            backgroundColor: '#015d63'
        }
    }
}]);

const cueBoxKpiContainerClassName =mergeStyles([{
    width: 145,
    height: 145,
    marginTop: 10,
    color: 'white',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 10,
    backgroundColor: '#008089',
    selectors: {
        "&:hover": {
            backgroundColor: '#015d63'
        }
    }
}]);

const cueBoxKpiContentContainerClassName = mergeStyles([{
    position: 'relative'
}]);

const cueBoxKpiIconContainerClassName = mergeStyles([{
    fontSize: 25,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 90
}]);

const cueBoxKpiTitleContainerClassName = mergeStyles([{
    fontSize: 14,
    paddingBottom: 10,
    width: '100%',
    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif',
    display: 'flex',
    justifyContent: 'center',
    height: 20
}]);

const cueBoxContentContainerClassName = mergeStyles([{
    position: 'relative'
}]);

const cueBoxTitleContainerClassName = mergeStyles([{
    fontSize: 14,
    paddingBottom: 10,
    width: '100%',
    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif',
    display: 'flex',
    justifyContent: 'center',
    height: 20
}]);

const cueBoxIconContainerClassName = mergeStyles([{
    fontSize: 45,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 70
}]);

const cueBoxSeparatorContainerClassName = mergeStyles([{
    marginTop: 10,
    marginLeft: 5,
    fontSize: 13,
    borderTop: '2px solid white',
    width: 110,
    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif'
}]);

export const CueBox: React.FC<ICueBoxProps> = (props) => {
    const navigate = useNavigate();
    
    const handleOnClick = (isIconBox:boolean)=>{
        if(isIconBox && !props.openExternalLink)
            navigate(props.link);
        else
            window.open(props.link, "_blank");
    }

    return (
        <Stack key={"cueBoxContainer-" + props.name} className={cueBoxContainerClassName} onClick={()=>handleOnClick(false)}>
            <Stack.Item className={cueBoxContentContainerClassName}>
                <Stack key="cueBoxDataContainer">
                    <Stack.Item className={cueBoxIconContainerClassName}>
                        {props.isIconBox ? (
                            <Icon iconName={props.iconName} />
                        ) : (
                            <>{props.value}</>
                        )}
                    </Stack.Item>
                    <Stack.Item className={cueBoxTitleContainerClassName}>
                        {props.title}
                    </Stack.Item>
                    <Stack.Item className={cueBoxSeparatorContainerClassName}>
                        <Icon iconName="ChevronRight" styles={{
                            root: {
                                paddingTop: 7,
                                paddingLeft: 4
                            }
                        }} />
                </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

//#endregion

//#region Data Box
export interface IDataBoxProps {
    name: string;
    title: string;
    value?: any;
    isIconBox?: boolean;
    iconName?: string;
    link:string;
    openExternalLink?: boolean;
    customStyle?: string;
    id?: string;
    disabled?: boolean;
}

const dataBoxContainerClassName = mergeStyles([{
    width: 100,
    height: 100,
    marginTop: 10,
    color: 'white',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 10,
    backgroundColor: '#008089',
    selectors: {
        "&:hover": {
            backgroundColor: '#015d63'
        }
    }
}]);

const dataBoxKpiContainerClassName = mergeStyles([{
    width: 122,
    height: 138,
    marginTop: 10,
    padding: 5,
    color: 'white',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: '#008089',
    selectors: {
        "&:hover": {
            backgroundColor: '#015d63'
        }
    }
}]);

const dataBoxKpiContentContainerClassName = mergeStyles([{
    position: 'relative'
}]);

const dataBoxKpiTitleContainerClassName = mergeStyles([{
    fontSize: 13,
    width: '100%',
    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif',
    height: 30,
    padding: 5 
}]);

const separatorKpiContainerClassName = mergeStyles([{
    fontSize: 13,
    borderTop: '2px solid white',
    width: 110,
    fontFamily: '"Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif'
}]);



export const DataBox: React.FC<IDataBoxProps> = (props) => {
    const navigate = useNavigate();
    
    const handleOnClick = (isIconBox:boolean)=>{
        if(!props.openExternalLink)
            navigate(props.link);
        else
            window.open(props.link, "_blank");
    }

    let dataBoxKpiIconContainerClassName = mergeStyles([{
        fontSize: 45,
        padding: 6,
        paddingTop: 5,
        fontWeight: 300,
        height: 70,
        textAlign: 'left',
        width: '100%'
    }]);

    if (props.value.length > 3)
        dataBoxKpiIconContainerClassName = mergeStyles([{
            fontSize: 34,
            padding: 6,
            paddingTop: 5,
            fontWeight: 300,
            height: 70,
            textAlign: 'left',
            width: '100%'
        }]);
    
    const getTitle = ()=>{
        if(props.title.length > 30)
            return props.title.substring(0, 27).trim() + "...";
        else
            return props.title;
    }

    const disabledStyle: CSSProperties = {
        backgroundColor: (props.disabled) ? 'lightgray' : '#008089',
    }

    return (
        <Stack key={"dataBoxContainer-" + props.name} id={props.id} className={props.customStyle ? props.customStyle : dataBoxKpiContainerClassName}
            onClick={() => {
                if (props.disabled)
                    return;
                    
                handleOnClick(true)
            }}
            style={disabledStyle}
        >
            <Stack.Item className={dataBoxKpiContentContainerClassName}>
                <Stack key="dataBoxDataContainer" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Stack.Item className={dataBoxKpiTitleContainerClassName}>
                        <TooltipHost
                            content={props.title}
                            id={"tootlip-"+props.name}
                            calloutProps={{ gapSpace: 0 }}
                            styles={{root:{display:'inline-block !important'}}}
                        >
                            {getTitle()} 
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item className={dataBoxKpiIconContainerClassName}>
                        {props.value}
                    </Stack.Item>
                    <Stack.Item className={separatorKpiContainerClassName}>
                        <Icon iconName="ChevronRight" styles={{
                            root: {
                                paddingTop: 7,
                                paddingLeft: 4
                            }
                        }} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

//#endregion


//#region Customer Chat Bot

export interface IChatbotProps {
    botTitle:string,
    chatContainerStyle:string,
    botButtonStyle:string,
    targetId:string
    showOpen?: boolean;
}

export const ChatBot : React.FC<IChatbotProps> = (props:IChatbotProps) => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [chatBotVisible, setChatBotVisible] = useState(props.showOpen);
    const [directLine, setDirectLine] = useState<any>(null);
    const [store, setStore] = useState<any>(null);
    const [videoUrl,setVideoUrl] = useState<string>("");
    const [showVideoModal,setShowVideoModal] = useState<boolean>(false);

    const sessionInfoContext = useContext<ISessionStateContext>(SessionStateContext);

    const containerClassName = mergeStyles({
        position: 'fixed',
        bottom: 150,
        right: 15,
    });
    
    const botButtonClassName = mergeStyles([{
        root: {
            backgroundColor: '#008089 !important',
            borderColor: '#008089 !important',
            width: "80px",
            height: "80px",
            borderRadius:"100%",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
        },
        rootHovered: {
            backgroundColor: 'rgb(1, 93, 99) !important',
            borderColor: 'rgb(1, 93, 99) !important'
        },
        icon:{
            fontSize: 50
        }
    }])

    const chatbotContainerClassName= mergeStyles([{
        width:310,
        height: 500,
        backgroundColor:'#00B7C3',
        borderRadius: 10,
        position: 'fixed',
        bottom: 15,
        right: "8%",
        boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.2)", 
    }])

    const chatbotHeaderClassName = mergeStyles([{
        backgroundColor: '#007bff',
        height:50,
        color: 'white',
        borderTopLeftRadius:10,
        borderTopRightRadius:10
    }])

    const chatbotTextClassName = mergeStyles([{
        position:"relative",
        color: 'white',
        top:10,
        paddingLeft:10
    }])

    const chatbotReduceButtonClassName = mergeStyles([{
        position:"relative",
        top:12,
        fontSize: 16,
        fontWeight: 900,
        left: 90,
        backgroundColor:'#007bff',
        color:'white',
        selectors:{
            ':hover':{
                color: '#f5f5f5',
                cursor: 'pointer'
            }
        }
    }])

    const chatContainerClassName = mergeStyles([{
        backgroundColor:'#00B7C3',
        padding:10,
        height:450
    }])

    const styleOptions = {
       // Add styleOptions to customize web chat canvas
       hideUploadButton: false
    };

    const getVideoUrl = (videoEntry: string) => {
        return new Promise((resolve, rejects) => {
            let client: AxiosInstance = HttpHelper.buildAxiosInstance();
            client.get("api/csp/video/" + videoEntry + "/generate")
                .then((resp) => {
                    setVideoUrl(resp.data);
                    setShowVideoModal(true);
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, t('demoCenter:ChatBot:Err00001Text'));
                })
        });
    }

   useEffect(()=>{
        if (dataStatus === SystemDataLoadingStatus.ToLoad){//get data for bot            
            fetch("https://directline.botframework.com/v3/directline/tokens/generate",{
                method: 'POST',
                headers: new Headers({
                        'Authorization': "Bearer NFPkY7sH3yQ.JDN2nGaiEgAl71E1f6WXSJ5ovMa1N1NMEwpIuj8pxMo"
                }),
            })
                .then(response => response.json())
                .then((conversationInfo:any) => {
                    const directLine = createDirectLine({ token: conversationInfo.token });
                    setDirectLine(directLine);
                    setStore(createStore(
                        {},
                        ( {dispatch}:any )  => (next:any) => async (action:any)=> {
                            if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
                                if ((action.payload != undefined) && (action.payload.activity.text != undefined))
                                {
                                    if (action.payload.activity.text.indexOf("WATCHVIDEO#") >= 0)
                                    {
                                        let values = action.payload.activity.text.split("#");
                                        getVideoUrl(values[1]);
                                        action.payload.activity.text = t('demoCenter:ChatBot:BotFramework:watchVideoText')
                                    }
                                    else if (action.payload.activity.text.indexOf("READWIKI#") >= 0)
                                    {
                                        let values = action.payload.activity.text.split("#");
                                        let wikiUrl = "";
                                        for (let i = 1; i < values.length; i++)
                                            wikiUrl += values[i];
                                        
                                        window.open(wikiUrl);
                                        action.payload.activity.text = t('demoCenter:ChatBot:BotFramework:readWikiText')
                                    }
                                }
                            }
                            else if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                                let sourceAddress: string = HttpHelper.getParameter("sourceAddress");
                                if ((sourceAddress != null) && (sourceAddress != undefined))
                                    sourceAddress = atob(sourceAddress);
                                else
                                    sourceAddress = "";

                                let title: string = HttpHelper.getParameter("title");
                                if ((title != null) && (title != undefined))
                                    title = atob(title);
                                else
                                    title = "";

                                let isHelpSession: boolean = (title != "") || (sourceAddress != "");

                                directLine.postActivity({
                                    from: {
                                        id: sessionInfoContext.currentUser.email,
                                        name: sessionInfoContext.currentUser.displayName
                                    },
                                    name: 'SOSMART/SESSIONINFO',
                                    type: 'event',
                                    value: '',
                                    channelData: {
                                        "sourceAddress": sourceAddress,
                                        "title": title,
                                        "isHelpSession": isHelpSession,
                                        "displayName": sessionInfoContext.currentUser.displayName
                                    }
                                }).subscribe(function(id: any) {
                                });
                            }
                            else if(action.type === 'DIRECT_LINE/POST_ACTIVITY'){
                                if ((action.payload != undefined) && (action.payload.activity != undefined))
                                    action.payload.activity.channelData = sessionInfoContext.currentUser;
                            }  
        
                          return next(action);
                       }
                    ));
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((jqXHR) => {
                    ToastService.showMessage(MessageType.Error, t('demoCenter:ChatBot:Err00002Text'));
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                });
        }
    },[dataStatus]);


    if(SystemCore.isFinishedDataStatus(dataStatus))
        return (
            <>
                <Stack className={containerClassName} id={props.targetId} hidden={chatBotVisible}>
                    <PrimaryButton 
                        id={"chatBotButton"}
                        onClick={()=>{!chatBotVisible ? setChatBotVisible(true) : setChatBotVisible(false)}}
                        text={t('demoCenter:ChatBot:title')!}
                        styles={{
                            root: {
                                backgroundColor: '#007bff',
                                borderColor: '#007bff',
                                width: "100px",
                                height: "100px",
                                borderRadius:"100%",
                                fontSize: 16,
                                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                            },
                            rootHovered: {
                                backgroundColor: '#043e7d',
                                borderColor: '#043e7d'
                            }
                        }}
                    />
                </Stack>
                <Stack 
                    className={chatbotContainerClassName}
                    hidden={!chatBotVisible}>
                    <Stack.Item className={chatbotHeaderClassName}>
                        <Text variant="xLarge" className={chatbotTextClassName}>{props.botTitle}</Text>
                        <Icon className={chatbotReduceButtonClassName} iconName="ChevronDown" onClick={()=>{setChatBotVisible(false)}} />
                    </Stack.Item>
                    <Stack.Item className={props.chatContainerStyle} id={'webChat'}>
                        <ReactWebChat directLine={directLine} store={store} userID={sessionInfoContext.currentUser.email} username={"Pippo"} styleOptions={styleOptions} />
                    </Stack.Item>
                </Stack>
                <DemoChatVideoModal hideModal={showVideoModal} videoName={""} videoUrl={videoUrl} onCloseModal={() => {
                    setShowVideoModal(false);
                }} />
            </>
        )
    else
        return <></>
}

//#endregion


//#region Business Central Progress Task

export interface IBusinessCentralModuleTelemetryProps {
    maxValue: number;
    minValue: number;
    eduTaskCompleted: number;
    prodTaskCompleted: number;
    environments: CustomerEnvironment[];
}

export const BusinessCentralModuleTelemetry: React.FC<IBusinessCentralModuleTelemetryProps> = (props) => {
    
    const generateColor = (isProduction: boolean,isTaskIndicator: boolean) => {
        if(isProduction){
            if(isTaskIndicator){
                if (props.prodTaskCompleted <= 50)
                    return "#E65E6D";
                else if (props.prodTaskCompleted > 50 && props.prodTaskCompleted < 90)
                    return "#E89E63";
                else if (props.prodTaskCompleted >= 90)
                    return "#008089";
            }
        }
        else{
            if(isTaskIndicator){
                if (props.eduTaskCompleted <= 50)
                    return "#E65E6D";
                else if (props.eduTaskCompleted > 50 && props.eduTaskCompleted < 90)
                    return "#E89E63";
                else if (props.eduTaskCompleted >= 90)
                    return "#008089";
            }
        }
    }

    const isEnvironmentVisible = (isProduction: boolean) : boolean => {
        if(isProduction)
            return props.environments.findIndex(prod => prod.production && prod.active) !== -1;
        
        return props.environments.findIndex(edu => !edu.production && edu.active) !== -1;
    }
    
    //#region Styles

    const environmentContainerClassName = mergeStyles([{
        width: '36%'
    }]);
    
    const environmentMainContainerClassName = mergeStyles([{
        width: '100%',
        minHeight: '320px',
        display: 'grid',
        placeItems: 'center',
    }]);

    const envTitleClassName = mergeStyles([{
        textAlign:"center",
        marginBottom:50,
        textDecoration:"underline",
        fontFamily:"Nunito",
        fontWeight:"700"
    }]);

    const prodTaskIndicatorTitleClassName = mergeStyles([{
        color: generateColor(true,true),
        textAlign:"center",
        paddingBottom:10
    }]);

    const prodFeedbackIndicatorTitleClassName = mergeStyles([{
        color: generateColor(true,false),
        textAlign:"center",
        paddingBottom:10
    }]);

    const eduTaskIndicatorTitleClassName = mergeStyles([{
        color: generateColor(false,true),
        textAlign:"center",
        paddingBottom:10
    }]);

    const eduFeedbackIndicatorTitleClassName = mergeStyles([{
        color: generateColor(false,false),
        textAlign:"center",
        paddingBottom:10
    }]);

    //#endregion

    const prodTaskCompleted: JSX.Element =
        <Stack
            className={environmentContainerClassName}
        >
            <Text variant="xLarge" className={prodTaskIndicatorTitleClassName}>{t('customerCenter:BusinessCentralTelemetry:taskTitle')!}</Text>
            <CircularProgressbar
                value={props.prodTaskCompleted}
                circleRatio={0.5}
                maxValue={props.maxValue}
                minValue={props.minValue}
                text={`${props.prodTaskCompleted}%`}
                styles={buildStyles({
                    rotation: 0.75,
                    pathColor: generateColor(true,true),
                    trailColor: "#A7ADB6",
                    textColor: generateColor(true,true)
                })}
            />
        </Stack>

    const eduTaskCompleted: JSX.Element =
        <Stack
            className={environmentContainerClassName}
        >
            <Text variant="xLarge" className={eduTaskIndicatorTitleClassName}>{t('customerCenter:BusinessCentralTelemetry:taskTitle')!}</Text>
            <CircularProgressbar
                value={props.eduTaskCompleted}
                circleRatio={0.5}
                maxValue={props.maxValue}
                minValue={props.minValue}
                text={`${props.eduTaskCompleted}%`}
                styles={buildStyles({
                    rotation: 0.75,
                    pathColor: generateColor(false,true),
                    trailColor: "#A7ADB6",
                    textColor: generateColor(false,true)
                })}
            />
        </Stack>

    return (
        <Stack
            className={environmentMainContainerClassName}
        >
            {
                props.environments.length === 0 ?
                    <BusinessCentralWaitingEnvironment />
                :
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                    >
                        <Stack
                            horizontal
                            horizontalAlign="space-around"
                            styles={{root:{marginTop:50}}}
                        >
                            <Stack
                                verticalAlign="stretch"
                                verticalFill
                            >
                                <Text variant="xLargePlus" className={envTitleClassName}>Educational</Text>
                                <Stack
                                    horizontal
                                    horizontalAlign="space-evenly"
                                >
                                    {eduTaskCompleted}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack
                            horizontal
                            horizontalAlign="space-around"
                            styles={{root:{marginTop:50}}}
                        >
                            <Stack
                                verticalAlign="stretch"
                                verticalFill
                            >
                                <Text variant="xLargePlus" className={envTitleClassName}>Production</Text>
                                <Stack
                                    horizontal
                                    horizontalAlign="space-evenly"
                                >
                                    {prodTaskCompleted}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
            }
        </Stack>
    )
}

//#endregion

//#region Business Central waiting environment

export const BusinessCentralWaitingEnvironment: React.FC = (props) => {

    const iconClassName = mergeStyles([{
        fontSize: 130,
        marginBottom:20,
        color: 'rgb(1, 93, 99)'
    }]);


    return (
        <Stack horizontal horizontalAlign="center" styles={{root:{height:'100%'}}}>
            <Stack verticalFill verticalAlign="center">
                <Stack horizontal horizontalAlign="center">
                    <Icon iconName="PowerBILogo16" className={iconClassName}></Icon>
                </Stack>
                <Stack horizontal horizontalAlign="center" styles={{root:{marginBottom:10,}}}>
                    <Text variant="superLarge" styles={{root:{color:'#008089'}}}>{t('customerCenter:BusinessCentralTelemetry:waitTitle')!}</Text>
                </Stack>
                <Stack horizontal horizontalAlign="center">
                    <Text variant="mediumPlus">{t('customerCenter:BusinessCentralTelemetry:waitSubTitle')!}</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}

//#endregion