import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { SystemPageType } from "../../../Model/SystemModels";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { useContext } from "react";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";

export const PartnerLeadOfferDetailList : React.FC = () => {
    const navigate = useNavigate();

    const partnerInfoContext = useContext(PartnerInformationContext);

    let listTitle = t('partnerCenter:partnerLeadOfferDetailList:title')!;
    let headerId = 0;

    const buildListEndpoint = () : string => {
        const headerIdParam = HttpHelper.getParameter("headerId");

        if (!headerIdParam)
            navigate("/app/partner/home");

        headerId = headerIdParam;

        return "/odata/SalesLineTemplate?$filter=salesHeaderTemplateId eq "+headerIdParam;
    }

    return (
        <>
            <PageContainer pageType={SystemPageType.List} title={listTitle} endpoint={buildListEndpoint()} backAllowed refreshAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:id')!} fieldName="id" minWidth={50} maxWidth={60} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:eolItemId')!} fieldName="eolItemId" minWidth={100} maxWidth={100} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:description')!} fieldName="description" minWidth={350} maxWidth={60} />
                    {/* <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:eolItemDescription')!} fieldName="eolItemDescription" minWidth={350} maxWidth={150} /> */}
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:notes')!} fieldName="notes" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:quantity')!} fieldName="quantity" minWidth={100} maxWidth={600} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:unitPrice')!} fieldName="unitPrice" minWidth={100} maxWidth={150} onRender={(item: any, index, column) => {
                        const currency = partnerInfoContext.partner.legalEntityCode === "ESS" ? "$ " : "€ ";
                        return currency + item.unitPrice
                    }} />
                    <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:lineAmount')!} fieldName="lineAmount" minWidth={100} maxWidth={150} onRender={(item: any, index, column) => {
                        const currency = partnerInfoContext.partner.legalEntityCode === "ESS" ? "$ " : "€ ";
                        return currency + item.lineAmount
                    }} />
                    {/* <ListHeaderEntry name={t('partnerCenter:partnerLeadOfferDetailList:fields:vatPercentage')!} fieldName="vatPercentage" minWidth={100} maxWidth={150} /> */}
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}
