import React, { useContext, useEffect, useRef, useState } from "react";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../Model/SystemModels";
import { PartnerInformationContext } from "../../../ApplicationCode/Partner/PartnerInformationContext";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { DataSecurityStatus } from "../../../Model/AdminModel";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../../Common/Loader/Loader";
import { t } from "i18next";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { useNavigate } from "react-router-dom";
import { Customer } from "../../../Model/PartnerModels";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";

export const PartnerCustomerList : React.FC = () => {    
    const currentPage = useRef<PageContainerBase>(null);    
    const partnerInfoContext = useContext(PartnerInformationContext);
    const adminService = new AdminService();
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [dsStates, setDsStates] = useState<DataSecurityStatus[]>([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {
            adminService.getDsStatusValue('Customer')
            .then((resp) => {
                setDsStates(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((err) => {
                console.log(err);
                setDataStatus(SystemDataLoadingStatus.Error);
            })
        }
    }, []);

    const buildListEndpoint = () => "/odata/customer?$filter=partnerId eq "+partnerInfoContext.partner.id;

    const downloadSubscriptions = () => {        
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/ecommerce/excel/partner/customer/subscriptions";
        var promise = new Promise((resolve,reject)=>{
            instance.get(endpoint, {responseType: 'blob'})
            .then((resp)=>{
                if (resp.data.size > 0) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Subscriptions.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    ToastService.showMessage(MessageType.Success, t('partnerCenter:partnerDashboard:components:downloadSuccess')!);
                    resolve(true);
                } else {
                    ToastService.showMessage(MessageType.Information, t('partnerCenter:partnerDashboard:components:noSubs')!);
                    resolve(true);
                }
            })
            .catch((resp)=>{
                console.log(resp.response.data.message);
                reject(resp.response.data.message);
            })
        })

        ToastService.showPromiseMessage(promise, t('partnerCenter:partnerDashboard:components:downloadStart')!, "", t('partnerCenter:partnerDashboard:components:downloadError')!);
    }

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t('partnerCenter:partnerDashboard:loadingMessage')!} />
    else
        return (
            <>
                <PageContainer pageType={SystemPageType.List} title={t('partnerCenter:partnerCustomerList:title')!} endpoint={buildListEndpoint()} ref={currentPage} backAllowed refreshAllowed>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="openCustomerSubscription" label={t('partnerCenter:partnerCustomerList:actions:openCustomerSubscription')!} iconName="KnowledgeArticle" isPromoted runOnRec onClick={(req: Customer) => {
                                    navigate("/app/partner/administrative/customer/subscription?customerId="+req.id+"&name="+req.companyName);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="downloadSubscription" label={t('partnerCenter:partnerCustomerList:actions:downloadSubscriptions')!} iconName="Download" isPromoted onClick={() => {
                                downloadSubscriptions();
                            }} />
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:id')!} fieldName="id" minWidth={60} maxWidth={100} />
                        <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:statusCode')!} fieldName="statusCode" minWidth={150} maxWidth={500} onRender={(item: any, index, column) => {
                            return dsStates.find(ds => ds.code === item.statusCode)?.description;
                        }} />
                        <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:companyName')!} fieldName="companyName" minWidth={500} maxWidth={600} />
                        <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:email')!} fieldName="email" minWidth={350} maxWidth={500} />
                        <ListHeaderEntry name={t('partnerCenter:partnerCustomerList:fields:phone')!} fieldName="phone" minWidth={250} maxWidth={500} />
                    </PageDefinitionContainer>
                </PageContainer>
            </>
        );
}