import { Checkbox, ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, IModalProps, IStackProps, IStackStyles, MaskedTextField, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { IndustyType, TrialData } from "../../../Model/DemoModels";
import { Lead } from "../../../Model/PartnerModels";
import { PostalCode } from "../../../Model/SystemModels";
import { t } from "i18next";

export interface ITrialModalProps {
    onSave: () => void;
    onCancel: () => void;
    onChange: (data: TrialData) => void;
    validationErrors: object[];
    industryTypes: IndustyType[];
    showModal: boolean;
    savingData: boolean;
    trialData: TrialData;
    currentLead:Lead;
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '50%' } },
};

const stackTokens = { childrenGap: 40 };
const stackStyles: Partial<IStackStyles> = { root: { width: '100%' } };

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: t('demoCenter:TrialModal:Dialog:title'),
    subText: t('demoCenter:TrialModal:Dialog:subText'),
};

const modalProps: Partial<IModalProps> = {
    isBlocking: true,
    styles: {
        main: {
            minWidth: 600,
            maxWidth: 1000
        }
    }
};

const options: IComboBoxOption[] = [];
let zipCodeData:PostalCode[] = [];
let zipCodeOptions: IComboBoxOption[] = [];
let cityOptions: IComboBoxOption[] = [];
let countyOptions: IComboBoxOption[] = [];
let countryOptions: IComboBoxOption[] = [];
let selectedCityKey: string;
let selectedCountyKey: string;
let selectedCountryKey: string;

export class TrialModal extends React.Component<ITrialModalProps> {

    constructor(props: ITrialModalProps)
    {
        super(props);

        this.loadIndustryTypeOptions();
        this.getCompanyNameFromLead();
        this.getAllZipCodes();
    }

    loadIndustryTypeOptions = () => {
        while (options.length > 0)
        {
            options.pop();
        }
        
        options.push({
            key: 0,
            text: t('demoCenter:TrialModal:Dialog:defaultIndustryType')!
        });
      
        this.props.industryTypes.forEach(element => {
            options.push({
                key: element.id,
                text: element.translatedDescription
            });
        });
    };

    getFieldErrorMessage = (fieldName: string) : string => {
        let result = this.props.validationErrors.filter((e: any) => e.reference == fieldName && e.applyToField);
        if (result.length > 0)
        {
            let errorObject: any = result[0];
            return errorObject.error;
        }
        return "";
    }

    getCompanyNameFromLead = () => {
        if(this.props.currentLead != null){
            let currentData: any = this.props.trialData;
            currentData.customerUser.customer.companyName = this.props.currentLead.companyName;
            this.props.onChange(currentData);
        }
    }

    getAllZipCodes = () => {
        let instance = HttpHelper.buildAxiosInstance();
        instance.get("/odata/PostalCode").then((resp) => {
            zipCodeData=resp.data;
            this.parseZipCodeOptions(resp.data);
            this.findZipCodeFromInput(this.props.trialData.currentLead.zipCode!);
        })
        .catch((jqXHR)=>{
            console.log(jqXHR.message);
        })
    }

    parseZipCodeOptions = (zipCodeData:any) => {
        zipCodeData.forEach((element:any) => {
            zipCodeOptions.push({
                key: element.zipCode+"-"+element.city,
                text: element.zipCode+"  -  "+element.city
            });
        });
    }

    findZipCodeFromInput = (input:string) => {
        cityOptions = [];
        countyOptions = [];
        countryOptions = [];
        selectedCityKey = "";
        selectedCountyKey = "";
        selectedCountryKey = "";

        let currentPostalCodeDatas: PostalCode[] = zipCodeData.filter((postalCode: PostalCode) => postalCode.zipCode === input || postalCode.city === input);
         
        let currentData: any = this.props.trialData;

        currentData.currentLead.city = "";
        currentData.currentLead.county = "";
        currentData.currentLead.country = "";
        this.props.onChange(currentData);

        if(currentPostalCodeDatas !== undefined && currentPostalCodeDatas.length === 1) {
            let record: IComboBoxOption = {key: currentPostalCodeDatas[0].city!, text: currentPostalCodeDatas[0].city!}
            selectedCityKey = currentPostalCodeDatas[0].city!
            cityOptions.push(record);
            currentData.currentLead.city = currentPostalCodeDatas[0].city!;
            
            record = {key: currentPostalCodeDatas[0].county!, text: currentPostalCodeDatas[0].county!}
            selectedCountyKey = currentPostalCodeDatas[0].county!;
            countyOptions.push(record);
            currentData.currentLead.county = currentPostalCodeDatas[0].county!;
            
            record = {key: currentPostalCodeDatas[0].countryCode!, text: currentPostalCodeDatas[0].countryCode!}
            selectedCountryKey = currentPostalCodeDatas[0].countryCode!;
            countryOptions.push(record);
            currentData.currentLead.country = currentPostalCodeDatas[0].countryCode!;
        }
        else if(currentPostalCodeDatas !== undefined && currentPostalCodeDatas.length > 1) {
            currentPostalCodeDatas.forEach((element:any) => {
                let record: IComboBoxOption = {key: "", text: ""}
                if (!cityOptions.find((city:any) => city.key === record.key))
                    cityOptions.push(record);

                record = {key: element.city!, text: element.city!}
                if (!cityOptions.find((city:any) => city.key === record.key))
                    cityOptions.push(record);

                record = {key: element.county!, text: element.county!}
                if (!countyOptions.find((county:any) => county.key === record.key))
                    countyOptions.push(record);
                
                record = {key: element.countryCode!, text: element.countryCode!}
                if (!countryOptions.find((country:any) => country.key === record.key))
                    countryOptions.push(record);
                
                selectedCityKey = "";
                if (cityOptions.length === 1){
                    selectedCityKey = cityOptions[0].key!.toString();
                    currentData.currentLead.city = cityOptions[0].text!;
                }
                
                selectedCountyKey = "";
                if (countyOptions.length === 1){
                    selectedCountyKey = countyOptions[0].key!.toString();
                    currentData.currentLead.county = countyOptions[0].text!;
                }
                
                selectedCountryKey = "";
                if (countryOptions.length === 1){
                    selectedCountryKey = countryOptions[0].key!.toString();
                    currentData.currentLead.country = countryOptions[0].text!;
                }
            })
        }
        else{
            currentData.currentLead.city = "";
            currentData.currentLead.county = "";
            currentData.currentLead.country = "";
        }

        currentData.currentLead.zipCode = input.trim()!;
        this.props.onChange(currentData);
    }

    onChangeZipText = (input: string) =>{
        if (!this.props.trialData.currentLead.foreignAddress)
            this.findZipCodeFromInput(input);
        else
        {
            let currentData: any = this.props.trialData;
            currentData.currentLead.zipCode = input.trim()!;
            this.props.onChange(currentData);
        }
    }

    renderZipCodePlaceHolder = (): string => {
        if (!this.props.trialData.currentLead.foreignAddress)
            return t('demoCenter:TrialModal:Dialog:Content:zipCodePlaceHolder')!
        
        return "";
    }
    
    render() {
        let stateMessage: JSX.Element = <></>;
        if (this.props.showModal)
        {

            let generalError = this.props.validationErrors.filter((e: any) => !e.applyToField);
            if (generalError.length > 0)
            {
                let errorResult: any = generalError[0];
                stateMessage = <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                >
                {errorResult.error}
              </MessageBar>
            }
            else if (this.props.savingData)
            {
                stateMessage = <MessageBar
                                    messageBarType={MessageBarType.info}
                                    isMultiline={false}
                                >
                {t('demoCenter:TrialModal:Dialog:messageContent1')!}
              </MessageBar>
            }
        }

        return (
            <>
              <Dialog
                hidden={!this.props.showModal}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                minWidth={900}
                maxWidth={1000}
              >
                <Stack 
                    horizontal 
                    tokens={stackTokens} 
                    styles={stackStyles}
                >
                    <Stack {...columnProps} key="content">
                        <TextField 
                            key="companyName" 
                            label={t('demoCenter:TrialModal:Dialog:Content:companyName')!}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.customerUser.customer.companyName = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.customerUser.customer.companyName}
                            tabIndex={1}
                            errorMessage={this.getFieldErrorMessage("companyName")}
                            disabled={this.props.savingData} 
                        />
                        <TextField 
                            key="firstname" 
                            label={t('demoCenter:TrialModal:Dialog:Content:name')!}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.currentLead.firstname = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.currentLead.firstname}
                            tabIndex={3}
                            errorMessage={this.getFieldErrorMessage("firstname")}
                            disabled={this.props.savingData} 
                        />
                        <TextField  
                            key="fullUsersNo" 
                            type="number"
                            label={t('demoCenter:TrialModal:Dialog:Content:fullUsersNo')!}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.currentLead.full_Users_No = Number(value!);
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.currentLead.full_Users_No.toString()}
                            errorMessage={this.getFieldErrorMessage("fullUsersNo")}
                            tabIndex={5}
                            disabled={this.props.savingData}
                        />
                        <TextField 
                            key="password"
                            type="password" 
                            label={t('demoCenter:TrialModal:Dialog:Content:password')!}
                            canRevealPassword={true}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.customerUser.password = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.customerUser.password}
                            errorMessage={this.getFieldErrorMessage("password")}
                            tabIndex={7}
                            disabled={this.props.savingData}
                        />
                        <Checkbox
                            key="abroadCustomer"
                            label={t('demoCenter:TrialModal:Dialog:Content:foreignAddress')!}
                            styles={{root:{
                                paddingTop: 30,
                                paddingBottom: 11
                            }}}
                            onChange={(e, checked) => {
                                cityOptions = [];
                                countyOptions = [];
                                countryOptions = [];
                                selectedCityKey = "";
                                selectedCountyKey = "";
                                selectedCountryKey = "";
                                
                                let currentData: any = this.props.trialData;
                                
                                currentData.currentLead.zipCode = "";
                                currentData.currentLead.county = "";
                                currentData.currentLead.city = "";
                                currentData.currentLead.country = "";

                                if (checked)
                                    currentData.currentLead.county = "EE";
                                
                                currentData.currentLead.foreignAddress = checked;
                                this.props.onChange(currentData);
                            }}
                        />
                        <TextField
                            key="zipCode"
                            label={t('demoCenter:TrialModal:Dialog:Content:zipCode')!}
                            value={this.props.trialData.currentLead.zipCode}
                            errorMessage={this.getFieldErrorMessage("zipCode")}
                            onChange={(e, value) => {this.onChangeZipText(value!)}}
                            tabIndex={10}
                            disabled={this.props.savingData}
                            placeholder={this.renderZipCodePlaceHolder()}
                            maxLength={10}
                        />
                        {
                            (!this.props.trialData.currentLead.foreignAddress) ?
                            <ComboBox
                                key="city"
                                label={t('demoCenter:TrialModal:Dialog:Content:city')!}
                                options={cityOptions}
                                selectedKey={selectedCityKey}
                                onChange={(e, value) => {
                                    if (value === undefined || value.text === undefined || value.text === "")
                                        selectedCityKey = "";
                                        
                                        let currentData: any = this.props.trialData;
                                        currentData.currentLead.city = value?.text!;
                                        this.props.onChange(currentData);
                                    }}
                                    errorMessage={this.getFieldErrorMessage("city")}
                                    tabIndex={11}
                                    disabled={this.props.savingData || !(this.props.trialData.currentLead.zipCode! > "")}
                                    autoComplete="on"
                            /> :
                            <TextField
                                key="labelCity"
                                label={t('demoCenter:TrialModal:Dialog:Content:city')!}
                                onChange={(e, value) =>{
                                    let currentData: any = this.props.trialData;
                                    currentData.currentLead.city = value!;
                                    this.props.onChange(currentData);
                                }}
                                value={this.props.trialData.currentLead.city}
                                errorMessage={this.getFieldErrorMessage("city")}
                                tabIndex={11}
                                disabled={this.props.savingData}
                            />
                        }
                    </Stack>
                    <Stack {...columnProps}>
                        <TextField 
                            key="lastname" 
                            label={t('demoCenter:TrialModal:Dialog:Content:lastName')!}
                            errorMessage={this.getFieldErrorMessage("lastname")}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.currentLead.lastname = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.currentLead.lastname}
                            tabIndex={4}
                            disabled={this.props.savingData} 
                        />
                        <TextField  
                            key="privateEmail" 
                            type="text"
                            label={t('demoCenter:TrialModal:Dialog:Content:privateEmail')!}
                            value={this.props.trialData.customerUser.user.privateEmail}
                            tabIndex={6}
                            disabled 
                        />
                        <TextField 
                            key="confirmPassword"
                            type="password" 
                            label={t('demoCenter:TrialModal:Dialog:Content:confirmPassword')!}
                            errorMessage={this.getFieldErrorMessage("confirmPassword")}
                            canRevealPassword={true}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.confirmPassword = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.confirmPassword}
                            tabIndex={8}
                            disabled={this.props.savingData} 
                        />
                        <TextField 
                            key="address"
                            type="text" 
                            label={t('demoCenter:TrialModal:Dialog:Content:address')!}
                            canRevealPassword={true}
                            onChange={(e, value) => {
                                let currentData: any = this.props.trialData;
                                currentData.currentLead.address = value!;
                                this.props.onChange(currentData);
                            }}
                            value={this.props.trialData.currentLead.address}
                            errorMessage={this.getFieldErrorMessage("address")}
                            tabIndex={9}
                            disabled={this.props.savingData}
                        />
                        {
                            (!this.props.trialData.currentLead.foreignAddress) ?
                            <ComboBox
                                key="county"
                                label={t('demoCenter:TrialModal:Dialog:Content:county')!}
                                options={countyOptions}
                                selectedKey={selectedCountyKey}
                                onChange={(e, value) => {
                                    let currentData: any = this.props.trialData;
                                    currentData.currentLead.county = value?.text!;
                                    this.props.onChange(currentData);
                                }}
                                errorMessage={this.getFieldErrorMessage("county")}
                                tabIndex={12}
                                disabled={this.props.savingData || !(this.props.trialData.currentLead.zipCode! > "")}
                                autoComplete="on"
                            /> :
                            <TextField
                                key="labelCounty"
                                label={t('demoCenter:TrialModal:Dialog:Content:county')!}
                                onChange={(e, value) =>{
                                    if (value!.length > 2)
                                    {
                                        value = value!.substring(0,2);
                                    }
                                    let currentData: any = this.props.trialData;
                                    currentData.currentLead.county = value!;
                                    this.props.onChange(currentData);
                                }}
                                value={this.props.trialData.currentLead.county}
                                errorMessage={this.getFieldErrorMessage("county")}
                                tabIndex={12}
                                disabled={this.props.savingData || this.props.trialData.currentLead.county === "EE"}
                            />
                        }
                        {
                            (!this.props.trialData.currentLead.foreignAddress) ?
                            <ComboBox
                                key="country"
                                label={t('demoCenter:TrialModal:Dialog:Content:country')!}
                                options={countryOptions}
                                selectedKey={selectedCountryKey}
                                onChange={(e, value) => {
                                    let currentData: any = this.props.trialData;
                                    currentData.currentLead.country = value?.text!;
                                    this.props.onChange(currentData);
                                }}
                                errorMessage={this.getFieldErrorMessage("country")}
                                tabIndex={13}
                                disabled={this.props.savingData || !(this.props.trialData.currentLead.zipCode! > "")}
                                autoComplete="on"
                            /> :
                            <TextField
                                key="labelCountry"
                                label={t('demoCenter:TrialModal:Dialog:Content:country')!}
                                onChange={(e, value) =>{
                                    let currentData: any = this.props.trialData;
                                    currentData.currentLead.country = value!;
                                    this.props.onChange(currentData);
                                }}
                                value={this.props.trialData.currentLead.country}
                                errorMessage={this.getFieldErrorMessage("country")}
                                tabIndex={13}
                                disabled={this.props.savingData}
                                maxLength={2}
                            />
                        }
                    </Stack>
                </Stack>
                <Stack key="errorMsg" style={{
                    marginTop: 20
                }}>
                    {stateMessage}
                </Stack>
                <DialogFooter>
                  <PrimaryButton onClick={() => {this.props.onSave()}} text={t('demoCenter:TrialModal:Dialog:primaryButtonText')!} disabled={this.props.savingData} />
                  <DefaultButton onClick={() => {this.props.onCancel()}} text={t('demoCenter:TrialModal:Dialog:defaultButtonText')!} disabled={this.props.savingData} />
                </DialogFooter>
              </Dialog>
            </>
          );
    }
}