import { CheckboxVisibility, Text, CommandBar, DetailsListLayoutMode, IColumn, Selection, IComboBoxOption, ICommandBarItemProps, IStyle, SelectionMode, ShimmeredDetailsList, Stack, mergeStyles, Pivot, PivotItem, Spinner, SpinnerSize, ConstrainMode, TextField, ContextualMenu, ColumnActionsMode, DirectionalHint, IContextualMenuItem, mergeStyleSets, IButtonStyles, IconButton, FontWeights, DatePicker, IComboBox, Checkbox, IChoiceGroupOption, PrimaryButton, Icon } from "@fluentui/react";
import { SystemDataLoadingStatus, SystemFieldType, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { SystemCore } from "../../../Core/System/SystemCore";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardField } from "../Template/Card/CardField";
import { CardGroup } from "../Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../Template/PageContainer";
import { CrmSetup, Lead, LeadQuestionnaireData, LeadQuestionnaireDataModified, LeadQuestionnaireInputType, LeadQuestionnaireRow } from "../../../Model/PartnerModels";
import { ContainerType, PageDefinitionContainer } from "../Template/PageDefinitionContainer";
import { ListHeaderEntry } from "../Template/List/ListHeaderEntry";
import { AdminLossRiskLineCard } from "../../Admin/Related/AdminLossRiskLineCard/AdminLossRiskLineCard";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { BookmarkManagement } from "../../../ApplicationCode/Common/BookmarkManagement";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ConfirmDialog } from "../Template/Dialog/ConfirmDialog";
import { t } from "i18next";

//#region Qualifier Container

export const QualifierContainer : React.FC<{}> = (props) => {
    
    const containerClassName = mergeStyles([{
        width: 'calc(100vw - 20px)',
        height: 'calc(100vh - 81px)',
        borderRadius: '10px',
        margin: '15px auto',
        border: '1px solid #d9d9d9',
        boxShadow: '6px 7px 5px 0px rgba(230,230,230,1)',
        padding: '10px'
    }]);

    
    return (
        <Stack
            horizontal
            horizontalAlign="center"
        >
            <Stack 
                className={containerClassName}
            >
                {props.children}
            </Stack>
        </Stack>
    );
}

//#endregion

//#region Qualifier Inner Container

export const QualifierInnerContainer : React.FC<{}> = (props) => {
    
    const innerContainerClassName = mergeStyles([{
        width: '100% !Important',
        height: '100% !Important'
    }]);

    
    return (
        <Stack
            verticalFill
            verticalAlign="space-between"
            className={innerContainerClassName}
        >
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Qualifier Content Container

export const QualifierContentContainer : React.FC<{}> = (props) => {
    
    const contentContainerClassName = mergeStyles([{
        width: '100% !Important',
        height: 'calc(100% - 50px) !Important',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
    }]);

    
    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            wrap
            className={contentContainerClassName}
        >
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Qualifier Lead list

export interface IQualifierLeadListProps {
    leadDataModified: LeadQuestionnaireDataModified[] | any;
    items: any[];
    itemToSelect: string;
    dataStatus: SystemDataLoadingStatus;
    crmSetup: CrmSetup;
    onFilterList: (ev?: any | undefined, item?: IContextualMenuItem | undefined, newValue?: string, freeForm?: boolean,modifiedDataFound?: boolean) => void;
    onSelectionChanged: (selectedItem: any,modifiedDataFound?: boolean) => void;
}

export const QualifierLeadList : React.FC<IQualifierLeadListProps> = (props) => {
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [target, setTarget] = useState<EventTarget & HTMLElement>();
    const [filterChecked, setFilterChecked] = useState<string>('');
    const [itemToSelect, setItemToSelect] = useState<any>(null);
    const [selectionMode,setSelectionMode] = useState<SelectionMode>(SelectionMode.single);
    const [selectionValue, setSelectionValue] = useState<any>(null);
    const [selection, setSelection] = useState<Selection>(new Selection({
        canSelectItem: (): boolean =>{
            return props.leadDataModified.length === 0;
        },
        onSelectionChanged: () => {
            const currentSelection = selection.getSelection();
            setSelectionValue(currentSelection[0]);
            if(currentSelection.length > 0){
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                props.onSelectionChanged(currentSelection[0],modifiedDataFound);
            }
            else
                props.onSelectionChanged(null,false);
        },
        getKey: (item: any) : string | number => {
            return item.systemId;
        }
    }));
    
    //#region Styles

    const leadListContainerClassName = mergeStyles([{
        width: '20% !important',
        height: '100% !Important',
        borderBottomLeftRadius: '10px'
    }]);

    const statusTextBoxClassName = mergeStyles([{
        width: '100% !important',
        marginTop: '10px !important'
    }]);

    //#endregion

    //#region Columns - Combobox

    let columns:IColumn[] = [
        {
            key: 'leadCompanyName',
            name: 'Lead',
            fieldName: 'companyName',
            minWidth: 50,
            columnActionsMode: ColumnActionsMode.hasDropdown,
            onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn | undefined) => {
                setShowContextMenu(true);
                setTarget(ev.currentTarget);
            }
        },
        {
            key: 'score',
            name: 'Score',
            fieldName: 'score',
            minWidth: 60,
            onRender: (item:Lead, index:number | undefined, column: IColumn | undefined) => {
                return (<span style={{color:'#008089'}}>{item.score}</span>);
            }
        }
    ]

    const menuOptions:IContextualMenuItem[] = [
        {
            key: '',
            name: '',
            canCheck: true,
            checked: filterChecked === '',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;
                
                setFilterChecked('');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '00',
            name: '00 - Interest',
            canCheck: true,
            iconProps: {iconName: 'InternetSharing'},
            checked: filterChecked === '00',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('00');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '02',
            name: '02 - First contact',
            canCheck: true,
            iconProps: {iconName: 'Contact'},
            checked: filterChecked === '02',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('02');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '20',
            name: '20 - Develop',
            canCheck: true,
            iconProps: {iconName: 'DeveloperTools'},
            checked: filterChecked === '20',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('20');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '50',
            name: '50 - Propose',
            canCheck: true,
            iconProps: {iconName: 'TriggerApproval'},
            checked: filterChecked === '50',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('50');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '60',
            name: '60 - Trial',
            canCheck: true,
            iconProps: {iconName: 'TriggerApproval'},
            checked: filterChecked === '60',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('60');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        },
        {
            key: '80',
            name: '80 - Buy',
            canCheck: true,
            iconProps: {iconName: 'ShoppingCart'},
            checked: filterChecked === '80',
            onClick: (ev?: any | undefined, item?: IContextualMenuItem | undefined) => {
                let modifiedDataFound: boolean = false;
                if(props.leadDataModified.length > 0)
                    modifiedDataFound = true;

                setFilterChecked('80');
                props.onFilterList(ev,item,'',false,modifiedDataFound);
            }
        }
    ]

    //#endregion

    const handleSelectionMode = () => {
        if(props.leadDataModified.length > 0)
            setSelectionMode(SelectionMode.none);
        else
            setSelectionMode(SelectionMode.single)
    }

    useEffect(()=>{
        if(props.dataStatus === SystemDataLoadingStatus.Loaded){
            if(props.itemToSelect === '')
                return;

            selection.toggleAllSelected();
            selection.setItems(props.items);
            selection.setKeySelected(props.itemToSelect, true, true);
        }
    },[props.dataStatus])

    useEffect(()=>{
        handleSelectionMode();
    },[props.leadDataModified])
    
    return (
        <Stack
            verticalFill
            verticalAlign="start"
            className={leadListContainerClassName}
        >   
            <TextField
                onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined)=>{
                    let modifiedDataFound: boolean = false;
                    if(props.leadDataModified.length > 0)
                        modifiedDataFound = true;
                    
                    props.onFilterList(event, undefined,newValue,true,modifiedDataFound);
                }}
                label="Ricerca"
                placeholder="Ricerca un valore"
                className={statusTextBoxClassName}
                iconProps={{iconName: 'Filter'}}
            />      
            <ShimmeredDetailsList
                setKey="systemId"
                items={props.items}
                columns={columns}
                selection={selection}
                selectionMode={selectionMode}
                enableShimmer={!SystemCore.isFinishedDataStatus(props.dataStatus)}
                checkboxVisibility={CheckboxVisibility.hidden}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                onActiveItemChanged={(item,index,ev) => setItemToSelect(item)}
                listProps={{
                    usePageCache: true,
                    onShouldVirtualize: () => true,
                    contentEditable: false,
                    onClick: () => {
                        if(props.leadDataModified.length > 0)
                            setShowConfirmDialog(true);
                    }
                }}
                detailsListStyles={{
                    contentWrapper:{
                        width: '100% !important',
                        maxHeight: 'calc(100vh - 297px) !important',
                        bordderBottomLeftRadius: '10px !important',
                        overflowY: 'auto !important',
                        overflowX: 'hidden !important'
                    }
                }}
            />
            <ContextualMenu
                target={target}
                directionalHint={DirectionalHint.bottomCenter}
                onDismiss={()=>setShowContextMenu(false)}
                hidden={!showContextMenu}
                items={menuOptions}  
            />
            <ConfirmDialog 
                cancelButtonText="Annulla"
                confirmButtonText="Conferma"
                showDialog={showConfirmDialog}
                title="Modifiche non salvate"
                subText="Attenzione sono presenti modifiche non salvate. Vuoi continuare?"
                onConfirm={() => {
                    props.onSelectionChanged(itemToSelect,false);

                    selection.toggleAllSelected();
                    selection.setItems(props.items);
                    selection.setKeySelected(itemToSelect.systemId, true, true);

                    setShowConfirmDialog(false);
                    setItemToSelect(null);
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                    setItemToSelect(null);
                }} 
            />
        </Stack>
    );
}

//#endregion

//#region Qualifier Lead Details Container

export const QualifierLeadDetailsContainer : React.FC<{}> = (props) => {
    
    const contentContainerClassName = mergeStyles([{
        width: '78% !Important',
        height: '100% !Important',
        borderBottomRightRadius: '10px',
    }]);

    
    return (
        <Stack
            verticalFill
            verticalAlign="space-between"
            className={contentContainerClassName}
        >
            {props.children}
        </Stack>
    );
}

//#endregion

//#region Qualifier Pivot

export interface IQualifierPivotProps{
    record: Lead;
    operationType: SystemOperation;
    totalRiskLine:number,
    totalQualifiedAnswer:number,
    leadQuestionnaireData: LeadQuestionnaireData[];
    leadQuestionnaireDataModified: LeadQuestionnaireDataModified[];
    keyToSelect: string;
    dataStatus: SystemDataLoadingStatus;
    onSave: (operationType: SystemOperation, record: Lead) => void;
    onSaveQuestionnairesData?: (questionnaireId: string) => Promise<boolean>;
    onModifyQuestionnaireData?: (questionnaireId:string,key:string, newValue: any) => Promise<boolean>;
    onPivotItemClick: (item?: PivotItem | undefined, ev?: any | undefined, modifiedDataFound?: boolean) => void;
}

export const QualifierPivot : React.FC<IQualifierPivotProps> = (props) => {
    const [jsxQuestionnaires,setJSXQuestionnaires] = useState<JSX.Element[]>([]);
    const cardRef = useRef<PageContainerBase>(null);
    
    //#region Styles
    
    const pivotContainerClassName = mergeStyles([{
        height:'calc(100vh - 240px)',
        overflowY:'auto !important'
    }]);

    const pivotInnerContainerClassName = mergeStyles([{
        width: '100% !Important',
        height: '100% !Important',
        margin: '15px !Important'
    }]);
    
    const pivotDetailsContainerClassName = mergeStyles([{
        width: '100% !Important',
        height: '100% !Important',
        margin: '15px !Important',
    }]);
    
    const pivotInteractionContainerClassName = mergeStyles([{
        width: '100% !Important',
        height: '100% !Important',
        margin: '15px !Important',
        overflowY: 'auto !Important'
    }]);
    
    const pivotQuestionnaireContainerClassName = mergeStyles([{
        height: 'calc(100vh - 210px) !important',
        overflowY: 'auto !important'
    }]);

    //#endregion

    const generateQuestionnaires = () => {
        let questionnairesData: JSX.Element[] = [];
        let questionnaireRows: JSX.Element[] = [];

        props.leadQuestionnaireData.forEach((questionnaire: LeadQuestionnaireData) => {
            questionnaire.questionnaireRows.forEach((questionnaireRow: LeadQuestionnaireRow) => {
                questionnaireRows.push(
                    <QualifierQuestionnaireRow
                        questionnaireId={questionnaire.id}
                        questionKey={questionnaireRow.answerType}
                        InputType={questionnaireRow.isGroup ? LeadQuestionnaireInputType.None : questionnaireRow.inputType}
                        questionText={questionnaireRow.answerText}
                        currentValue={questionnaireRow.answerValue}
                        onInputChangeEvent={(event,key,questionnaireId,newValue)=>{
                            props.onModifyQuestionnaireData!(questionnaireId,key,newValue).then(()=>{
                                generateQuestionnaires();
                            })
                        }}
                        onCheckEvent={(key,questionnaireId,event,checked)=>{
                            props.onModifyQuestionnaireData!(questionnaireId,key,checked!.toString()).then(()=>{
                                generateQuestionnaires();
                            })
                        }}
                        onSelectDateEvent={(key,questionnaireId,date)=>{
                            props.onModifyQuestionnaireData!(questionnaireId,key,date!.toISOString()).then(()=>{
                                generateQuestionnaires();
                            })
                        }}
                    />
                );
            })

            questionnairesData.push(
                <QualifierQuestionnaire
                    id={questionnaire.id}
                    onSave={(id)=>{
                        props.onSaveQuestionnairesData!(id)
                    }}
                    title={questionnaire.title}
                    rowElement={questionnaireRows}
                />
            );
        });
        
        setJSXQuestionnaires(questionnairesData);
    }

    useEffect(()=>{
        if(props.leadQuestionnaireData.length > 0)
            generateQuestionnaires();
    },[props.leadQuestionnaireData])

    return (
        <>
            <Pivot 
                className={pivotInnerContainerClassName}
                overflowBehavior={'menu'}
                overflowAriaLabel="more items"
                onLinkClick={(item,ev)=>{
                    let modifiedDataFound: boolean = false;
                    if(props.leadQuestionnaireDataModified.length > 0)
                        modifiedDataFound = true;
                    
                    props.onPivotItemClick(item,ev,modifiedDataFound);
                }}  
                selectedKey={props.keyToSelect}
            >
                <PivotItem 
                    headerText="Dettaglio"
                    itemIcon="PageHeaderEdit"
                    itemKey="Default"
                    className={pivotDetailsContainerClassName}
                >
                    {
                        props.dataStatus === SystemDataLoadingStatus.Loading ?
                            <QualifierLoader />
                        :
                        <Stack className={pivotContainerClassName}>
                            <PageContainer pageType={SystemPageType.CardCustom} customRecord={props.record} customOperationType={props.operationType} onSuccess={props.onSave} endpoint="/odata/lead" title={'Scheda Lead'}>
                                <CardGroup name="general" label="Generale" isOpen={true}>
                                    <CardField name="companyName" type={SystemFieldType.String} label="Azienda"/>
                                    <CardField name="email" type={SystemFieldType.String} label="email"/>
                                    <CardField name="firstname" type={SystemFieldType.String} label="Nome" />
                                    <CardField name="lastname" type={SystemFieldType.String} label="Cognome" />
                                    <CardField name="phone" type={SystemFieldType.String} label="Telefono" />
                                    <CardField name="partnerId" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey="id" tableRelationField="name" label="Partner"/>
                                    <CardField name="tokenForDemo" type={SystemFieldType.String} label="Token Demo" readonly />
                                    <CardField name="partnerBusinessContractCode" type={SystemFieldType.String} label="Partner Business Contract" readonly />
                                    <CardField name="businessLineCode" label="Business line" type={SystemFieldType.String} readonly />
                                    <CardField name="statusCode" type={SystemFieldType.String} label="Stato DS" hasTableRelation tableRelationEndpoint="/odata/datasecurity/status" tableRelationFilter={"?$filter=entityName eq 'Lead'"} tableRelationKey="code" tableRelationField="description" readonly />
                                    <CardField name="score" type={SystemFieldType.String} label="Score" readonly />
                                    <CardField name="owningUserId" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/user" tableRelationKey="id" tableRelationField="displayName" tableRelationFilter="?$filter=crmSystemUserId ne null" label="Utente Proprietario" />
                                </CardGroup> 
                                <CardGroup name="address" label="Indirizzo" isOpen={false}>
                                    <CardField name="address" type={SystemFieldType.String} label="Indirizzo" />
                                    <CardField name="zipCode" type={SystemFieldType.String} label="C.A.P." />
                                    <CardField name="city" type={SystemFieldType.String} label="Città" />
                                    <CardField name="county" type={SystemFieldType.String} label="Provincia" />
                                    <CardField name="country" type={SystemFieldType.String} label="Paese" />
                                </CardGroup> 
                                <CardGroup name="marketingQualification" label="Qualifica Marketing" isOpen={false}>
                                    <CardField name="origin" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/source" tableRelationKey="code" tableRelationField="description" label="Origine" />
                                    <CardField name="campaignCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/campaign" tableRelationKey="code" tableRelationField="description" label="Campagna" />
                                    <CardField name="originName" type={SystemFieldType.String} label="Nome Origine" />
                                    <CardField name="noOfCompany" type={SystemFieldType.Number} label="Numero Società"/>
                                    <CardField name="full_Users_No" type={SystemFieldType.Number} label="Nr. utenti full" />
                                    <CardField name="limited_User_No" type={SystemFieldType.Number} label="Nr. utenti limited" />
                                    <CardField name="note" type={SystemFieldType.String} label="Note" />
                                    <CardField name="marketingQualificationCarriedOut" type={SystemFieldType.Boolean} label="Qualifica marketing effettuata" />
                                    <CardField name="monthlyBudget" type={SystemFieldType.Number} label="Bugdet mensile" />
                                    <CardField name="noOfEmployees" type={SystemFieldType.Number} label="Numero di impiegati" />
                                    <CardField name="atecoCode" type={SystemFieldType.String} label="Codice ATECO" readonly />
                                    <CardField name="atecoDescription" type={SystemFieldType.String} label="Descrizione ATECO" readonly />
                                    <CardField name="companyStartDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyStartDate")} readonly />
                                    <CardField name="companyRegistrationDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyRegistrationDate")} readonly />
                                    <CardField name="companyEndDate" type={SystemFieldType.Date} label={t("adminCenter:leadCard:companyEndDate")} readonly />
                                    <CardField name="balanceYear" type={SystemFieldType.Number} label={t("adminCenter:leadCard:balanceYear")} readonly />
                                    <CardField name="netWorth" type={SystemFieldType.Number} label={t("adminCenter:leadCard:netWorth")} readonly />
                                    <CardField name="shareCapital" type={SystemFieldType.Number} label={t("adminCenter:leadCard:shareCapital")} readonly />
                                    <CardField name="totalStaffCost" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalStaffCost")} readonly />
                                    <CardField name="totalAssets" type={SystemFieldType.Number} label={t("adminCenter:leadCard:totalAssets")} readonly />
                                    <CardField name="avgGrossSalary" type={SystemFieldType.Number} label={t("adminCenter:leadCard:avgGrossSalary")} readonly />
                                    <CardField name="digitalLevel" type={SystemFieldType.Number} label="Livello di digitalizzazione" />
                                    <CardField name="currentERPName" type={SystemFieldType.String} label="ERP attuale" />
                                    <CardField name="otherConnectedSystems" type={SystemFieldType.String} label="Altri sistemi interfacciati" />
                                    <CardField name="decisionDate" type={SystemFieldType.Date} label="Data decisione" />
                                    <CardField name="expectedLiveDate" type={SystemFieldType.Date} label="Data aspettativa live" />
                                    <CardField name="ourAgentRole" type={SystemFieldType.String} label="Ruolo agente SoSmart" />
                                    <CardField name="demoPartecipants" type={SystemFieldType.String} label="Partecipanti alla demo" />
                                    <CardField name="decisionMakerPath" type={SystemFieldType.String} label="Path di decisione" />
                                    <CardField name="freeTrialInterest" type={SystemFieldType.Boolean} label="Interessati alla free trial" />
                                    <CardField name="isAlreadyCustomer" type={SystemFieldType.Boolean} label="È già cliente" />
                                    <CardField name="activeMicrosoftServices" type={SystemFieldType.String} label="Servizi MS già attivi" />
                                </CardGroup> 
                                <CardGroup name="interactionsDetail" label="Dettaglio Interazione" isOpen={false}>
                                    <CardField name="demoBooked" type={SystemFieldType.Boolean} label="Demo prenotata" />
                                    <CardField name="demoDate" type={SystemFieldType.Date} label="Data demo" />
                                    <CardField name="internalMeetingBooked" type={SystemFieldType.Boolean} label="Meeting interno prenotato" />
                                    <CardField name="internalMeetingDate" type={SystemFieldType.Date} label="Data meeting interno" />
                                    <CardField name="demoCarriedOut" type={SystemFieldType.Boolean} label="Demo effettuata" />
                                    <CardField name="demoCarriedOutBy" type={SystemFieldType.Number} label="Demo effettuata da" hasTableRelation tableRelationEndpoint="odata/user" tableRelationFilter="?$filter=crmSystemUserId ne null" tableRelationKey="id" tableRelationField="displayName" />
                                    <CardField name="notManagedGAPEmerged" type={SystemFieldType.Boolean} label="Emersi GAP non gestiti" />
                                    <CardField name="negotiationState" type={SystemFieldType.String} label="Stato negoziazione" />
                                    <CardField name="rating" type={SystemFieldType.Option} label="Rating" cmbOptions={[
                                            {key: 0, text: "Fredda"},
                                            {key: 1, text: "Tiepida"},
                                            {key: 2, text: "Calda"}
                                        ]} />
                                    <CardField name="evaluationMode" type={SystemFieldType.Option} label="Modalità di valutazione" cmbOptions={[
                                        {key: 0, text: "Non indicata"},
                                        {key: 1, text: "Self Service"},
                                        {key: 2, text: "Con un consulente"}
                                        ]} />
                                </CardGroup>
                                <CardGroup name="leadAutomation" label="Lead automation" isOpen={false}>
                                    <CardField name="lastActivityDate" type={SystemFieldType.Date} label="Data ultima interazione" readonly />
                                    <CardField name="lastActivityType" type={SystemFieldType.Option} label="Tipo ultima interazione"  cmbOptions={[
                                        { key: 0, text: "Email" },
                                        { key: 1, text: "Note" },
                                        { key: 2, text: "Appuntamento" },
                                        { key: 3, text: "Post" },
                                        { key: 4, text: "Task" },
                                        { key: 5, text: "Telefonata" }
                                    ]} readonly />
                                    <CardField name="lastInboundEmailDate" type={SystemFieldType.Date} label="Data ultima email inbound" readonly />
                                    <CardField name="lastWeekInteractions" type={SystemFieldType.Number} label="Interazioni ultima settimana" readonly />
                                    <CardField name="lastMonthInteractions" type={SystemFieldType.Number} label="Interazioni ultimo mese" readonly />
                                    <CardField name="demoFeedbackRequired" type={SystemFieldType.Boolean} label="Feedback demo richiesto" />
                                    <CardField name="demoFeedbackReceived" type={SystemFieldType.Boolean} label="Feedback demo ricevuto" />
                                    <CardField name="demoFeedbackRating" type={SystemFieldType.Number} label="Rating feedback demo" />
                                    <CardField name="riskLineExists" type={SystemFieldType.Boolean} label="Presenza rischi perdita" readonly />
                                    <CardField name="riskLineHandled" type={SystemFieldType.Boolean} label="Rischi perdita gestiti" />
                                </CardGroup> 
                                <CardGroup name="offer" label="Offerta" isOpen={false}>
                                    <CardField name="offerLink" type={SystemFieldType.String} label="Link Offerta" />
                                    <CardField name="offerSent" type={SystemFieldType.Boolean} label="Offerta inviata" />
                                    <CardField name="promoCode" type={SystemFieldType.String} label="Codice promo" />
                                </CardGroup>
                                <CardGroup name="customer" label="Cliente" isOpen={false}>
                                    <CardField name="isTrial" type={SystemFieldType.Option} label="Cliente Trial" cmbOptions={[
                                        {key: 0, text: "Empty"},
                                        {key: 1, text: "On"},
                                        {key: 2, text: "Off"},
                                        {key: 3, text: "Converted"},
                                    ]} readonly />
                                    <CardField name="trialRequestedDate" type={SystemFieldType.Date} label="Data richiesta Trial" readonly />
                                    <CardField name="trialStartDate" type={SystemFieldType.Date} label="Data partenza Trial" readonly />
                                    <CardField name="createdOn" type={SystemFieldType.String} label="Data Creazione" readonly />
                                </CardGroup>
                                <CardGroup name="finalOutcome" label="Esito Finale" isOpen={false}>
                                    <CardField name="finalOutcome" type={SystemFieldType.Option} label="Esito Finale" cmbOptions={[
                                            {key: 0, text: ""},
                                            {key: 1, text: "Vinta"},
                                            {key: 2, text: "Persa"},
                                            {key: 3, text: "Non qualificata"}
                                        ]} />
                                    <CardField name="lossCauseCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/losscause" tableRelationKey="code" tableRelationField="description" label="Causa Perdita" />
                                    <CardField name="crmStatusCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/status/code" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=entityName eq 'Lead'" label="Causa squalifica" />
                                </CardGroup>
                            </PageContainer>  
                        </Stack>
                    }
                </PivotItem>
                <PivotItem 
                    itemCount={props.totalRiskLine} 
                    headerText="Rischio perdita" 
                    itemIcon="AlertSolid"
                    itemKey="RiskLine"
                    className={pivotInteractionContainerClassName}
                    headerButtonProps={{
                            disabled: props.record === null
                    }}
                >
                {
                    props.dataStatus === SystemDataLoadingStatus.Loading ?
                        <QualifierLoader />
                    :
                    <Stack verticalAlign="space-between" verticalFill className={pivotContainerClassName}>
                        <Stack>
                            <PageContainer endpoint={"/odata/crm/lossriskline?$filter=sourceTable eq 'Lead' and sourceId eq "+ props.record?.id} pageType={SystemPageType.List} title={"Righe Rischio Perdita"} cardRef={cardRef} backAllowed={false} insertAllowed updateAllowed deleteAllowed blockBookmark onNewRecord={() => {
                                cardRef.current?.openPage(SystemOperation.Insert);
                                cardRef.current?.setRecord({
                                    sourceTable: "Lead",
                                    sourceId: props.record?.id,
                                });
                            }}>
                                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                                <PageDefinitionContainer type={ContainerType.PageStructure}>
                                    <ListHeaderEntry name="Causa Perdita" fieldName="lossCauseDescription" minWidth={300} maxWidth={250} />
                                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={400} maxWidth={250} />
                                    <ListHeaderEntry name="Process Module" fieldName="processModule" minWidth={200} maxWidth={250} />
                                    <ListHeaderEntry name="Process Code" fieldName="processCode" minWidth={200} maxWidth={250} />
                                    <ListHeaderEntry name="Score Riga" fieldName="lineScore" minWidth={150} maxWidth={200} />
                                    <ListHeaderEntry name="Tabella Origine" fieldName="sourceTable" minWidth={150} maxWidth={250} />
                                    <ListHeaderEntry name="Id Origine" fieldName="sourceId" minWidth={100} maxWidth={250} />
                                    <ListHeaderEntry name="Id" fieldName="entryNo" minWidth={100} maxWidth={200} />
                                </PageDefinitionContainer>
                            </PageContainer>
                            <AdminLossRiskLineCard ref={cardRef} /> 
                        </Stack>
                    </Stack>
                }
                </PivotItem>
                <PivotItem
                    itemCount={props.totalQualifiedAnswer} 
                    headerText="Risposte qualifica" 
                    itemIcon="Unknown"
                    itemKey="QualifiedAnswer"
                    className={pivotInteractionContainerClassName}
                    headerButtonProps={{
                            disabled: props.record === null
                    }}
                >
                    {
                        props.dataStatus === SystemDataLoadingStatus.Loading ?
                            <QualifierLoader />
                        :
                        <Stack>
                            <PageContainer endpoint={"/odata/lead/qualified/answer?$filter=leadId eq "+props.record?.id+" and wizardCode eq null&$orderby=entryNo"} pageType={SystemPageType.List} title={"Riposte qualifica Lead"} blockBookmark backAllowed={false}>
                                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                                <PageDefinitionContainer type={ContainerType.PageStructure}>
                                    <ListHeaderEntry name="Codice risposta" fieldName="answerCode" minWidth={100} maxWidth={250} />
                                    <ListHeaderEntry name="Tipo risposta" fieldName="answerType" minWidth={100} maxWidth={250} />
                                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={700} maxWidth={850} />
                                    <ListHeaderEntry name="Score" fieldName="score" minWidth={80} maxWidth={100} />
                                    <ListHeaderEntry name="Note" fieldName="note" minWidth={700} maxWidth={850} />
                                </PageDefinitionContainer>
                            </PageContainer>
                        </Stack>
                    }
                </PivotItem>
                <PivotItem
                    itemCount={props.leadQuestionnaireData.length} 
                    headerText="Questionari" 
                    itemIcon="Questionnaire"
                    itemKey="Questionaire"
                    className={pivotQuestionnaireContainerClassName}
                    headerButtonProps={{
                        disabled: props.record === null || props.leadQuestionnaireData.length === 0
                    }}
                >
                    {
                        props.dataStatus === SystemDataLoadingStatus.Loading ?
                            <QualifierLoader />
                        :
                            <>
                                {jsxQuestionnaires}
                            </>
                    }
                </PivotItem>
            </Pivot>
        </>
    );
}

//#endregion

//#region Qualifier Loader

export const QualifierLoader : React.FC<{}> = (props) => {
    
    const contentContainerClassName = mergeStyles([{
        width: '100% !Important',
        height:'calc(100vh - 240px) !important',
        borderBottomRightRadius: '10px',
    }]);

    
    return (
        <Stack
            horizontalAlign="center"
            verticalAlign="center"
            className={contentContainerClassName}
        >
            <Spinner
                label="Caricamento dettaglio in corso..." 
                size={SpinnerSize.large}
                styles={{
                    label: {
                        fontSize: 13
                    },
                    circle: {
                        width: 70,
                        height: 70,
                        borderWidth: 2.5,
                        marginBottom: 10
                    }
                }}
            />
        </Stack>
    );
}

//#endregion

//#region Qualifier Questionnaire Container

export interface IQualifierQuestionnaireContainerProps{
    title: string;
    onSave: () => void;
}

export const QualifierQuestionnaireContainer : React.FC<IQualifierQuestionnaireContainerProps> = (props) => {
    const [isContentVisible, setIsContentVisible] = useState<boolean>(false);

    const containerClassName = mergeStyles([{
        width: '99% !important',
        maxHeight: 'calc(100vh - 250px) !important',
        marginTop: 20,
        marginBottom: 20
    }]);

    const ContentStyles = mergeStyleSets({
        Header:{
            borderBottom: `1px solid gray`,
            color: "black",
            fontWeight: FontWeights.semibold,
            marginBottom: '5px'
        }
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: "black",
            marginLeft: 'auto',
            marginRight: '2px',
            marginBottom: '5px'
        },
        rootHovered: {
            color: "gray",
        }
    };

    const updateIcon = () =>{
        if(isContentVisible)
            return "ChevronUpSmall";
        else
            return "ChevronDownSmall";
    }

    return(
        <Stack className={containerClassName}>
            <Stack className={ContentStyles.Header} horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <Text variant="xLarge">{props.title}</Text>
                </Stack.Item>
                <Stack.Item>
                    <PrimaryButton 
                        iconProps={{
                            iconName:"Save"
                        }}
                        styles={{
                            root:{
                                backgroundColor: 'transparent !important',
                                border: 'none !important',
                                boxShadow: 'none !important',
                                textShadow: 'none !important',
                                color: 'black !important',
                            },
                            rootHovered:{
                                backgroundColor: 'transparent !important',
                                color: '#008089 !important'
                            },
                            rootPressed:{
                                backgroundColor: 'transparent !important',
                                color: '#116EBE !important'
                            }
                        }}
                        onClick={props.onSave}
                    >
                        Salva
                    </PrimaryButton>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: updateIcon() }}
                        ariaLabel="Close page"
                        onClick={()=>{setIsContentVisible(!isContentVisible)}}
                    />
                </Stack.Item>
            </Stack>
            <Stack styles={{root:{display: isContentVisible ? 'none' : 'auto',overflowY:'auto'}}} hidden={isContentVisible}>
                {props.children}
            </Stack>
        </Stack>
    )
}

//#endregion

//#region Qualifier Questionnaire Content Container

export const QualifierQuestionnaireContentContainer : React.FC = (props) => {

    const containerClassName = mergeStyles([{
        width: '100% !important',
        height:'100% !important'
    }]);

    return(
        <Stack
            verticalAlign="start"
            verticalFill
            className={containerClassName}
        >
            {props.children}
        </Stack>
    )
}

//#endregion

//#region Qualifier Questionnaire Row

export interface IQualifierQuestionnaireRowProps{
    questionKey: string;
    questionnaireId: string;
    questionText: string;
    InputType: LeadQuestionnaireInputType;
    cmbOptions?: IComboBoxOption[];
    choiceOptions?: IChoiceGroupOption[];
    currentValue: any;
    label?: string;
    onInputChangeEvent?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,key: string,questionnaireId:string, newValue?: string | undefined) => void;
    onComboBoxChangeEvent?: (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => void;
    onSelectDateEvent?: (key: string,questionnaireId:string,date: Date | null | undefined) => void;
    onCheckEvent?: (key: string,questionnaireId:string,ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => void;
    onChoiceChangeEvent?: (ev?: FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => void;
}

export const QualifierQuestionnaireRow : React.FC<IQualifierQuestionnaireRowProps> = (props) => {
    const [value, setValue] = useState<any>(props.currentValue);

    const questionnaireRowContainerClassName = mergeStyles([{
        width: '100% !important',
        padding: 10
    }]);

    const textContainerClassName = mergeStyles([{
        width: '45% !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        justifyItems: 'start'
    }]);

    const inputContainerClassName = mergeStyles([{
        width: '55% !important'
    }]);

    const formatDate = (dateStr?: string): Date | undefined => {
        if (dateStr !== undefined)
        {
            let convert: Date = new Date(dateStr);
            if (convert.getFullYear() === 1)
                return undefined;
                
            return convert;
        }

        return undefined;
    }

    const generateAnswerObject = () : JSX.Element => {
        switch(props.InputType){
            case LeadQuestionnaireInputType.Text:
                return <TextField 
                            placeholder="Scrivi qui..." 
                            iconProps={{iconName:'EditNote'}} 
                            value={value} 
                            onChange={(event,newValue)=>{
                                setValue(newValue);
                                props.onInputChangeEvent!(event,props.questionKey,props.questionnaireId,newValue)
                            }}
                        />
            case LeadQuestionnaireInputType.Number:
                return <TextField 
                            placeholder="Scrivi qui..." 
                            type="number" 
                            value={value}
                            iconProps={{iconName:'EditNote'}} 
                            onChange={(event,newValue)=>{
                                setValue(newValue);
                                props.onInputChangeEvent!(event,props.questionKey,props.questionnaireId,newValue)
                            }} 
                        />
            case LeadQuestionnaireInputType.Date:
                return <DatePicker 
                            placeholder="Seleziona una data..." 
                            formatDate={(date) => {
                                let days, month, year;
    
                                if (date !== undefined)
                                {
                                    days = date.getDate();
                                    month = date.getMonth() + 1;
                                    year = date.getFullYear();

                                    return days + "/" + month + "/" + year;
                                }
                                return "";
                            }}
                            value={formatDate(value)}
                            onSelectDate={(date)=>{
                                if((date !== undefined) && (date !== null))
                                    date.setTime(date.getTime() + (2*60*60*1000));

                                setValue(date);
                                props.onSelectDateEvent!(props.questionKey,props.questionnaireId,date)
                            }} 
                        />
            case LeadQuestionnaireInputType.Boolean:
                return  <Checkbox 
                            label={props.label}
                            checked={JSON.parse(value)}
                            onChange={(ev,checked)=>{
                                setValue(checked);
                                props.onCheckEvent!(props.questionKey,props.questionnaireId,ev,checked)
                            }} 
                        />
            case LeadQuestionnaireInputType.None:
                return <></>
        }
    }

    return(
        <Stack
            horizontal
            horizontalAlign="start"
            className={questionnaireRowContainerClassName}
        >
            <Stack className={textContainerClassName}>
                <Text 
                    variant={props.InputType === LeadQuestionnaireInputType.None ? 'large' : 'medium'}
                    styles={{
                        root:{
                            fontWeight: props.InputType === LeadQuestionnaireInputType.None ? FontWeights.bold : FontWeights.regular,
                            color: 'black',
                            width: props.InputType !== LeadQuestionnaireInputType.None ? '88% !important' : '100% !important'
                        }
                    }}
                >
                    {props.questionText}
                </Text>
            </Stack>
            <Stack hidden={props.InputType === LeadQuestionnaireInputType.None} className={inputContainerClassName}>
                {generateAnswerObject()}
            </Stack>
        </Stack>
    )
}

//#endregion

//#region Qualifier Questionnaire

export interface IQualifierQuestionnaireProps{
    id: string;
    title: string;
    rowElement: JSX.Element[];
    onSave: (id: string) => void;
}

export const QualifierQuestionnaire : React.FC<IQualifierQuestionnaireProps> = (props) => {

    
    return(
        <QualifierQuestionnaireContainer
            title={props.title}
            onSave={()=>props.onSave(props.id)}
        >
            <QualifierQuestionnaireContentContainer>
                {props.rowElement}
            </QualifierQuestionnaireContentContainer>
        </QualifierQuestionnaireContainer>
    )
}

//#endregion

export const CommonLeadQualifierPage : React.FC<{}> = () => {
    const [dataStatus, setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [contentDataStatus, setContentDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const listItem = useRef<Lead[]>([]);
    const [totalListItem, setTotalListItem] = useState<Lead[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [itemToSelect, setItemToSelect] = useState<any>(null);
    const [leadListClicked, setLeadListClicked] = useState<boolean>(false);
    const [filterTimerId, setFilterTimerId] = useState<number>(0);
    const [searchText, setSerachText] = useState<string>('');
    const [selectedFilter, setSelectedFitler] = useState<string>('');
    const [totalRiskLine, setTotalRiskLine] = useState<number>(0);
    const [totalAnswer, setTotalAnswer] = useState<number>(0);
    const [leadQuestionnaireData, setLeadQuestionnaireData] = useState<LeadQuestionnaireData[]>([]);
    const idToSelect = useRef<string>('');
    const pivotKeyToSelectRef = useRef<string>('');
    const [pivotKeyToSelect, setPivotKeyToSelect] = useState<string>('');
    const [pivotItem,setPivotItem] = useState<PivotItem | undefined>(undefined);
    const [pivotEvent,setPivotEvent] = useState<any | undefined>(undefined);
    const [pivotItemClicked, setPivotItemClicked] = useState<boolean>(false);
    const [updateActionClicked, setUpdateActionClicked] = useState<boolean>(false);
    const [filterItem,setFilterItem] = useState<IContextualMenuItem | undefined>(undefined);
    const [filterEvent,setFilterEvent] = useState<any | undefined>(undefined);
    const [filterItemClicked, setFilterItemClicked] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string | undefined>('');
    const [filterFreeForm, setFilterFreeform] = useState<boolean | undefined>(false);
    const [questionnaireDataModified,setQuestionnaireDataModified] = useState<LeadQuestionnaireDataModified[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [crmSetup, setCrmSetup] = useState<CrmSetup>(new CrmSetup());

    const navigate = useNavigate();
    const bookmarkManagement = new BookmarkManagement(navigate);
    
    //#region Styles

    const commandBarStyle:IStyle = mergeStyles([{
        width: '100% !Important',
        height: 50,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottom: '1px solid #d9d9d9',
        padding: '0px'
    }]);

    //#endregion

    //#region Commandbar options

    const commandBarItems:ICommandBarItemProps[] = [
        {
            key: 'back',
            text: 'Indietro',
            iconProps: {iconName: 'Back'},
            onClick: () => {navigate(-1)}
        },
        {
            key: 'reload',
            text: 'Aggiorna',
            iconProps: {iconName: 'Sync'},
            onClick: () => {
                if(questionnaireDataModified.length > 0){
                    setUpdateActionClicked(true);
                    setShowConfirmDialog(true);
                }
                else
                    loadLeadData()
            }
        },
        {
            key: 'crmLead',
            text: 'Lead CRM',
            iconProps: {iconName: 'CRMServices'},
            disabled: (selectedItem === undefined || selectedItem === null),
            onClick: () => {
                window.open(crmSetup.baseAddress+selectedItem.crmLeadId);
            }
        }
    ]

    //#endregion   
    
    const handleListSelection = (item:Lead, modifiedDataFound?: boolean) => {
        if(modifiedDataFound){
            setItemToSelect(item);
            setLeadListClicked(true);

            setShowConfirmDialog(true);
        }
        else{
            setContentDataStatus(SystemDataLoadingStatus.Loading);
    
            if(item !== null){
                if(dataStatus === SystemDataLoadingStatus.Loaded || dataStatus === SystemDataLoadingStatus.ToLoad){
                    bookmarkManagement.setCurrentBookmark('bookmark',item.systemId);
                    setSelectedItem(item);
                    
                    getDataFromSelection(item.systemId);
                }
            }
            else{
                setSelectedItem(null);
            }
        }
    }

    const handleConfirmDialogResponse = () =>{
        setShowConfirmDialog(false);
        setQuestionnaireDataModified([]);
        
        if(pivotItemClicked){
            onPivotClick(pivotItem,pivotEvent,false);

            setPivotEvent(undefined);
            setPivotItem(undefined);
            setPivotItemClicked(false);
        }
        else if(leadListClicked){
            handleListSelection(itemToSelect,false);

            setItemToSelect(undefined);
            setLeadListClicked(false);
        }
        else if(updateActionClicked){
            loadLeadData();

            setUpdateActionClicked(false);
        }
        else if(filterItemClicked){
            onFilterList(filterEvent,filterItem,filterValue,filterFreeForm,false);

            setFilterEvent(undefined);
            setFilterItem(undefined);
            setFilterItemClicked(false);
            setFilterValue(undefined);
            setFilterFreeform(undefined);
        }
    }

    const getDataFromSelection = (systemId: string) => {
        const selectedLead: Lead = listItem.current.find((lead: Lead) => btoa(lead.systemId) === btoa(systemId))!;
        if(selectedLead === undefined)
            return;

        CommonFunctions.getLeadTotalRiskLine(selectedLead).then((resp) => {
            setTotalRiskLine(resp);
            CommonFunctions.getLeadTotalQualifiedAnswer(selectedLead).then((resp) => {
                setTotalAnswer(resp);
                
                CommonFunctions.getLeadQuestionnaireData(selectedLead).then((resp)=>{
                    setLeadQuestionnaireData(resp);
                    setQuestionnaireDataModified([]);
                    setContentDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch(()=>{
                    setContentDataStatus(SystemDataLoadingStatus.Loaded);
                })
            })
            .catch((jqXhr)=> {
                setContentDataStatus(SystemDataLoadingStatus.Loaded);
            })
        })
        .catch((jqXhr)=> {
            setContentDataStatus(SystemDataLoadingStatus.Loaded);
        })
    }

    const loadLeadData = () => {
        setDataStatus(SystemDataLoadingStatus.Loading);
        setContentDataStatus(SystemDataLoadingStatus.Loading);

        let leadFilter = "?$filter=(statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'02\' or statusCode eq \'00\') and (partnerBusinessContractCode eq \'IT-DIRETTA\' or partnerBusinessContractCode eq \'IT-REFERRAL\' or partnerBusinessContractCode eq \'IT-RESELLER\')&$orderby=score desc"

        CommonFunctions.loadWorksheetLeads(undefined, leadFilter).then((resp:Lead[]) => {
            setTotalListItem(resp);
            listItem.current = resp;

            CommonFunctions.getCrmSetup().then((resp) => {
                setCrmSetup(resp);
            
                let bookmark = HttpHelper.getParameter("bookmark");
                
                if(bookmark !== null && bookmark !== ''){
                    let currentBookmark = bookmarkManagement.getCurrentBookmark(bookmark);
                    if(currentBookmark !== null && currentBookmark !== ''){
                        idToSelect.current = currentBookmark!;
                        setDataStatus(SystemDataLoadingStatus.Loaded);

                        getDataFromSelection(currentBookmark!);
                    }
                    else{
                        setDataStatus(SystemDataLoadingStatus.Loaded);
                        setContentDataStatus(SystemDataLoadingStatus.Loaded);
                    }
                }
                else{
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                    setContentDataStatus(SystemDataLoadingStatus.Loaded);
                }
            })
            .catch((jqXhr)=>{
                ToastService.showMessage(MessageType.Error,'Errore durante il caricamento dei dati');
                setDataStatus(SystemDataLoadingStatus.Loaded);
                setContentDataStatus(SystemDataLoadingStatus.Loaded);
            })
        })
        .catch((jqXhr)=>{
            ToastService.showMessage(MessageType.Error,'Errore durante il caricamento dei dati');
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setContentDataStatus(SystemDataLoadingStatus.Loaded);
        })
    }

    const onFilterList = (ev?: any | undefined, item?: IContextualMenuItem | undefined, newValue?: string, freeForm?: boolean,modifiedDataFound?: boolean) => {
        if(modifiedDataFound){
            setFilterItem(item);
            setFilterEvent(ev);
            setFilterValue(newValue);
            setFilterFreeform(freeForm);
            setFilterItemClicked(true);

            setShowConfirmDialog(true);
        }
        else{
            setDataStatus(SystemDataLoadingStatus.Loading);
            let currListItems:any[] = [];
            
            currListItems = totalListItem;
    
            if((freeForm) && (newValue !== undefined) && (newValue !== '')){
                if(searchText === '')
                    setSerachText(newValue!);
                else
                    setSerachText('');
                
                currListItems = currListItems.filter(x => x.companyName.toLowerCase().includes(newValue!.toLowerCase()));
            }
            else if (searchText !== '' && (newValue !== undefined) && (newValue !== ''))
                currListItems = currListItems.filter(x => x.companyName.toLowerCase().includes(searchText!.toLowerCase()));
            else
                currListItems = totalListItem;
            
            if(item !== undefined){
                if(item?.key !== ''){
                    setSelectedFitler(item?.key);
                    currListItems = currListItems.filter(x => x.statusCode === item?.key);
                }
                else
                    setSelectedFitler('');
            }
            else if(selectedFilter !== '')
                currListItems = currListItems.filter(x => x.statusCode === selectedFilter);
    
            listItem.current = currListItems;
    
            if(filterTimerId !== 0)
                window.clearTimeout(filterTimerId);
    
            setFilterTimerId(
                window.setTimeout(() => {
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                }, 50)
            )
        }
    }

    const onPivotClick = (item?: PivotItem | undefined, ev?: any | undefined, modifiedDataFound?: boolean) => {
        if(modifiedDataFound){
            setPivotEvent(ev);
            setPivotItem(item);
            setPivotItemClicked(true);

            setShowConfirmDialog(true);
        }
        else{
            setPivotKeyToSelect(item?.props.itemKey!);
            pivotKeyToSelectRef.current = item?.props.itemKey!;
        }
    }

    const onSaveLeadData = (operationType: SystemOperation, record: Lead) => {
        setContentDataStatus(SystemDataLoadingStatus.Loading);

        CommonFunctions.saveLead(record).then((resp) => {
            loadLeadData();
            ToastService.showMessage(MessageType.Success,'Operazione completata');
            setContentDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((jqXhr)=>{
            ToastService.showMessage(MessageType.Error,'Errore durante il caricamento dei dati');
            setContentDataStatus(SystemDataLoadingStatus.Loaded);
        })

    }

    const onModifyQuestionnaire = (questionnaireId:string,key:string, newValue: any) : Promise<boolean> => {
        
        return new Promise<boolean>((resolve,reject)=>{
            let currentDataList: LeadQuestionnaireDataModified[] = questionnaireDataModified;
            let modifiedAnswer = questionnaireDataModified.find((data: LeadQuestionnaireDataModified) => data.questionnaireId === questionnaireId && data.answerType === key);
        
            if(modifiedAnswer !== undefined){
                modifiedAnswer.answerValue = newValue;
    
                let index = currentDataList.findIndex((data: LeadQuestionnaireDataModified) => data.questionnaireId === questionnaireId && data.answerType === key);
                if(index !== -1)
                    currentDataList[index] = modifiedAnswer;
            }
            else{
                let newAnswer: LeadQuestionnaireDataModified = new LeadQuestionnaireDataModified();
                newAnswer.questionnaireId = questionnaireId;
                newAnswer.answerType = key;
                newAnswer.answerValue = newValue;
    
                currentDataList.push(newAnswer);
            }
            
            setQuestionnaireDataModified(currentDataList);

            resolve(true);
        })
    }

    const onSaveQuestionnaireData = (questionnaireId: string) : Promise<boolean> => {
        setContentDataStatus(SystemDataLoadingStatus.Loading);

        return new Promise<boolean>((resolve,reject) => {
            CommonFunctions.updateLeadQuestionnaireData(selectedItem,questionnaireDataModified,questionnaireId).then((resp)=>{
                setQuestionnaireDataModified([]);

                loadLeadData();
    
                resolve(true);
            })
            .catch((jqXHR)=>{
                ToastService.showMessage(MessageType.Error, jqXHR.message);
                setContentDataStatus(SystemDataLoadingStatus.Loaded);
    
                reject(false);
            })
        })
    }

    useEffect(() => {
        if(dataStatus == SystemDataLoadingStatus.ToLoad)
            loadLeadData();
    }, [dataStatus])
    
    return (
        <>
            <QualifierContainer>
                <QualifierInnerContainer>
                    <CommandBar
                        styles={{root: commandBarStyle}}
                        items={commandBarItems}
                    />
                    <QualifierContentContainer>
                        <QualifierLeadList
                            key={'leadList'}
                            leadDataModified={questionnaireDataModified}
                            items={listItem.current}
                            itemToSelect={idToSelect.current}
                            onFilterList={onFilterList}
                            onSelectionChanged={handleListSelection}
                            dataStatus={dataStatus}
                            crmSetup={crmSetup}
                        />
                        <QualifierLeadDetailsContainer>
                            <QualifierPivot
                                leadQuestionnaireData={leadQuestionnaireData}
                                leadQuestionnaireDataModified={questionnaireDataModified}
                                keyToSelect={pivotKeyToSelect}
                                totalRiskLine={totalRiskLine}
                                totalQualifiedAnswer={totalAnswer}
                                onSave={onSaveLeadData}
                                onPivotItemClick={onPivotClick}
                                onSaveQuestionnairesData={onSaveQuestionnaireData}
                                onModifyQuestionnaireData={onModifyQuestionnaire}
                                operationType={SystemOperation.Update}
                                record={selectedItem}
                                dataStatus={contentDataStatus}
                            />
                        </QualifierLeadDetailsContainer>
                    </QualifierContentContainer>
                </QualifierInnerContainer>
            </QualifierContainer>
            <ConfirmDialog 
                cancelButtonText="Annulla"
                confirmButtonText="Conferma"
                showDialog={showConfirmDialog}
                title="Modifiche non salvate"
                subText="Attenzione sono presenti modifiche non salvate. Vuoi continuare?"
                onConfirm={() => {
                    handleConfirmDialogResponse();
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                }} 
            />
        </>
    );
}